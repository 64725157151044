import { string, bool, number, shape } from 'prop-types';

const userDataTypes = shape({
    balance: number,
    companyName: string,
    companyUrl: string,
    email: string,
    id: number,
    inviteEmail: bool,
    invitePhone: bool,
    inviteSms: bool,
    lastName: string,
    name: string,
    phone: string,
    profileId: number
});

export default userDataTypes;
