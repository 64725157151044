import React, { Fragment } from 'react';

import GoogleAnalytics from './GoogleAnalytics';
import YandexMetrika from './YandexMetrika';
import GoogleTagManager from './GoogleTagManager';

const GTM_PROPS = {
    gtmId: 'UA-86125927-1'
};

const Metrics = () => (
    <Fragment>
        <GoogleTagManager {...GTM_PROPS} />
        <GoogleAnalytics />
        <YandexMetrika />
    </Fragment>
);

export default Metrics;
