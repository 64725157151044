import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';
import { LocalStorage as LS } from './storage';

const FALLBACK_LANG = 'ru';
const SUPPORTED_LANGS = ['ru', 'en'];

/**
 * Getting the page locale
 */
const getPageLang = () => {
    const pageLang = LS.get('lang');

    if (SUPPORTED_LANGS.includes(pageLang)) {
        return pageLang;
    }

    return FALLBACK_LANG;
};

/**
 * Configurate i18next
 */
i18n.use(Backend)
    .use(reactI18nextModule)
    .init({
        backend: {
            loadPath: 'locale/{{lng}}.json'
        },

        // use keys as value on ru locale
        lng: getPageLang() !== 'ru' ? getPageLang() : undefined,

        fallbackLng: false,
        appendNamespaceToCIMode: true,
        saveMissing: false,

        debug: LS.getPlain('i18next-debug') === true,
        nsSeparator: false,
        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react
            formatSeparator: ',',
            // eslint-disable-next-line no-unused-vars
            format(value, format, lng) {
                if (format === 'uppercase') {
                    return value.toUpperCase();
                }

                return value;
            }
        },

        react: {
            wait: true,
            defaultTransParent: 'span'
            // nsMode: 'fallback'
        }
    });

/* Translate Tools for files not used localization provider */

/**
 * Alias for i18next.t()
 * @param {String} key
 */
function trans(i18nKey) {
    return i18n.t(i18nKey);
}

/**
 * @param {String} key
 * @param {Object} options https://i18next.gitbook.io/i18next/translation-function/essentials#overview-options
 */
function interpolate(i18nKey, options = {}) {
    return i18n.t(i18nKey, options);
}

/**
 * Hook for i18next parser
 * @param {string} i18nKey
 */
function parseTrans(i18nKey) {
    return i18nKey;
}

export default i18n;
export { trans, interpolate, parseTrans, FALLBACK_LANG, SUPPORTED_LANGS };
