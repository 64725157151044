import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cssModules from 'hoc/cssModules';
import Link from 'components/Router/Link';
import { Trans, withTranslate } from 'components/Localization';
import Svg from 'components/UI/Svg';

import styles from './Sidebar.module.styl';

@withTranslate
@cssModules(styles)
class Sidebar extends Component {
    static propTypes = {
        trans: PropTypes.func
    };

    render() {
        const { trans } = this.props;

        return (
            <aside styleName="sidebar">
                <Link to="/" styleName="sidebar__logo">
                    <Svg name="logo" alt="Robotvera" width={52} height={36} />
                </Link>

                <Link
                    to="/vacancies"
                    styleName="sidebar__item"
                    activeClass={styles.sidebar__item_active}
                >
                    <Svg
                        name="vacancies"
                        alt={trans('Вакансии')}
                        styleName="sidebar__icon"
                        width={30}
                        height={30}
                    />
                    <Trans>Вакансии</Trans>
                </Link>

                <Link
                    to="/responses"
                    styleName="sidebar__item"
                    activeClass={styles.sidebar__item_active}
                >
                    <Svg
                        name="responses"
                        alt={trans('Отклики')}
                        styleName="sidebar__icon"
                        width={30}
                        height={30}
                    />
                    <Trans>Отклики</Trans>
                </Link>

                {/* <Link
                    to="/control"
                    styleName="sidebar__item"
                    activeClass="sidebar__item_active"
                >
                    <Svg
                        name="control"
                        alt={trans('Управление персоналом')}
                        styleName="sidebar__icon"
                        width={30}
                        height={30}
                    />
                    <Trans>Управление персоналом</Trans>
                </Link> */}

                <Link
                    to="/statistic"
                    styleName="sidebar__item"
                    activeClass={styles.sidebar__item_active}
                >
                    <Svg
                        name="statistics"
                        alt={trans('Статистика')}
                        styleName="sidebar__icon"
                        width={27}
                        height={27}
                    />
                    <Trans>Статистика</Trans>
                </Link>

                {/* <Link
                    to="/interview"
                    styleName="idebar__item"
                    activeClass"sidebar__item_active"
                >
                    <Svg
                        name="interview"
                        alt={trans('Интервью')}
                        styleName"sidebar__icon"
                        width={30}
                        height={24}
                    />
                    <Trans>Интервью</Trans>
                </Link> */}

                {/* <Link
                    to="/calendar"
                    styleName="sidebar__item"
                    activeClass="sidebar__item_active"
                >
                    <Svg
                        name="calendar"
                        alt={trans('Календарь')}
                        styleName="sidebar__icon"
                        width={25}
                        height={25}
                    />
                    <Trans>Календарь</Trans>
                </Link> */}

                <a
                    href="http://hrobot.robotvera.com/faq"
                    target="_blank"
                    rel="noopener noreferrer"
                    styleName="sidebar__faq-link"
                >
                    ?
                </a>

                {process.env.version && (
                    <span styleName="sidebar__version">
                        {process.env.version}v
                    </span>
                )}
            </aside>
        );
    }
}

export default Sidebar;
