/* eslint-disable react/forbid-prop-types, react/no-children-prop */
import React from 'react';
import { Router as ReactRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

function Router({ history, children }) {
    return <ReactRouter history={history} children={children} />;
}

Router.displayName = 'SyncedRouter';

Router.propTypes = {
    history: PropTypes.object.isRequired,
    children: PropTypes.any
};

export default Router;
