import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';

import cssModules from 'hoc/cssModules';
import { withTranslate, Trans } from 'components/Localization';
import Spinner from 'components/UI/Spinner';
import Link from 'components/Router/Link';
import Button from 'components/UI/Button';

import styles from './Menu.module.styl';

@withTranslate
@inject(({ rootStore: { userStore }, authStore }) => ({
    userStore,
    authStore
}))
@observer
@cssModules(styles)
class LayoutDefaultTopbarMenu extends Component {
    static propTypes = {
        authStore: PropTypes.shape({
            logout: PropTypes.func.isRequired
        }),
        userStore: PropTypes.shape({
            currentUser: PropTypes.shape({
                roles: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string
                    })
                )
            })
        }),
        isLoaded: PropTypes.bool.isRequired,
        user: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        children: PropTypes.any // eslint-disable-line react/forbid-prop-types
    };

    constructor(props) {
        super(props);

        this.authStore = props.authStore;
        this.userStore = props.userStore;
    }

    render() {
        const { isLoaded, user, children } = this.props;
        const { logout, isLoginAs } = this.authStore;
        const { hasTeamsPermissions } = this.userStore;

        const menu = !isLoaded ? (
            <Spinner />
        ) : (
            <div styleName="topbar-menu">
                <div styleName="topbar-menu__name">
                    {user.name || user.email}
                </div>

                <nav>
                    {hasTeamsPermissions && (
                        <Link to="/teams" styleName="topbar-menu__link">
                            <Trans>Управление командой</Trans>
                        </Link>
                    )}
                    <Link to="/profile" styleName="topbar-menu__link">
                        <Trans>Мои данные</Trans>
                    </Link>
                    {!isLoginAs && (
                        <Button
                            styleName="
                            topbar-menu__link
                            topbar-menu__link-btn
                        "
                            onClick={logout}
                            transparent
                        >
                            <Trans>Выход</Trans>
                        </Button>
                    )}
                </nav>
            </div>
        );

        return <Dropdown overlay={menu}>{children}</Dropdown>;
    }
}

export default LayoutDefaultTopbarMenu;
