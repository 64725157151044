import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { routingTypes, authTypes } from 'types';

import LayoutAuth from 'components/Layout/Auth';
import LayoutDefault from 'components/Layout/Default';

@inject('routingStore', 'authStore')
@observer
class SmartRoute extends React.Component {
    static propTypes = {
        component: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.node,
            PropTypes.object,
            PropTypes.element
        ]),
        render: PropTypes.func,
        routingStore: routingTypes,
        authStore: authTypes,
        // eslint-disable-next-line
        history: PropTypes.object.isRequired,
        // eslint-disable-next-line
        computedMatch: PropTypes.object.isRequired,
        // eslint-disable-next-line
        location: PropTypes.object.isRequired,
        private: PropTypes.bool,
        title: PropTypes.string.isRequired,
        breadcrumbs: PropTypes.func
    };

    constructor(props) {
        super(props);

        const {
            routingStore,
            history,
            computedMatch,
            location,
            private: isPrivate,
            title,
            breadcrumbs
        } = props;

        routingStore.setRoute({
            history,
            computedMatch,
            location: { isPrivate, ...location },
            title,
            breadcrumbs
        });
    }

    render() {
        const {
            component: Component,
            routingStore: {
                location: { isPrivate }
            },
            authStore: { isAuthorized },
            private: privateRoute,
            ...rest
        } = this.props;
        const AppLayout = isPrivate ? LayoutDefault : LayoutAuth;

        if (isAuthorized && !privateRoute) {
            return <Route render={() => <Redirect to="/vacancies" />} />;
        }

        return (
            <AppLayout>
                <Route {...rest} render={props => <Component {...props} />} />
            </AppLayout>
        );
    }
}

export default SmartRoute;
