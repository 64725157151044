/* @ts-check */
/* eslint-disable camelcase */

import { viewModel } from 'tools/mobx-utils';

import { CANDIDATE_SOURCES, CANDIDATE_STATUSES } from 'constants/candidate';

@viewModel
class Candidate {
    /**
     * Creates an instance of Candidate.
     * @param {Object} candidate
     * @param {string} candidate.id - Candidate id
     * @param {string} candidate.name - Candidate name
     * @param {string} candidate.key - Candidate key for react
     * @param {string} candidate.vacancy_name - Candidate vacancy position name
     *
     * @param {string} candidate.email - Candidate email
     * @param {string} candidate.tel - Candidate tel number
     *
     * @param {string|number|Date} candidate.modified_at
     * @param {string|number|Date} candidate.created_at - date of candidate adding
     * @param {string|number|Date} candidate.feedback_at - date of response
     *
     * @param {string} candidate.source - Source is candidates from
     * @param {number} candidate.status - Candidate status
     */
    constructor({
        name,
        id,
        vacancy_name,
        tel,
        source,
        created_at,
        modified_at,
        feedback_at,
        status,
        email,
        dialog,
        comments,
        record_url,
        video_interview_url,
        hrefrtf,
        hrefrtf_docx,
        vacancy
    }) {
        this.key = id;
        this.id = id;

        this.name = name;
        this.tel = tel;
        this.email = email;

        /**
         * Candidate used statuses:
         * 0 - В стоп-листе вакансии
         * 1 - Еще нет результата
         * 31 - Отклик
         * 32 - Отказ
         * 34 - Назначено собеседование
         * 35 - Принят
         * 36 - Отклонен рекрутером
         * 38 - Видео-интервью
         * 39 - Телефонное интервью
         * 40 - Собеседование состоялось
         * 41 - Звонок состоялся
         * 200 - Не смогли связаться
         */
        this.status =
            (status &&
                CANDIDATE_STATUSES[status] &&
                CANDIDATE_STATUSES[status].text) ||
            '—';

        this.source = (source && CANDIDATE_SOURCES[source]) || '—';

        this.created_at = created_at;
        this.modified_at = modified_at;
        this.feedback_at = feedback_at;

        this.dialog = dialog;
        this.position = vacancy_name;
        this.comments = comments;
        this.record_url = record_url;
        this.vacancy = vacancy;
        this.video_interview_url = video_interview_url;

        this.hrefrtf = hrefrtf;
        this.hrefrtf_docx = hrefrtf_docx;
    }
}

export default Candidate;
