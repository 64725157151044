import React from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import i18n from 'tools/i18n';

function Localization({ children }) {
    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

Localization.propTypes = {
    children: PropTypes.any // eslint-disable-line
};

export default Localization;
