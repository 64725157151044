import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import cssModules from 'hoc/cssModules';

import Notification from 'components/UI/Notification';
import Topbar from './Topbar';
import Sidebar from './Sidebar';

import styles from './Default.module.styl';

@observer
@cssModules(styles)
class LayoutDefault extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.array,
            PropTypes.object,
            PropTypes.element,
            PropTypes.node
        ])
    };

    render() {
        const { children } = this.props;

        return (
            <div styleName="layout layout_default">
                <Sidebar />

                <div styleName="layout__wrapper">
                    <Topbar />

                    <div styleName="layout__content">{children}</div>

                    <Notification />
                </div>
            </div>
        );
    }
}

export default LayoutDefault;
