import { observable, action, set, computed } from 'mobx';

class RoutingStore {
    @observable title = '';

    @observable breadcrumbs;

    @observable history = {};

    @observable computedMatch = {};

    @observable
    location = {
        pathname: ''
    };

    @computed
    get pageTitle() {
        return this.title ? `RobotVera | ${this.title}` : 'Untitled page';
    }

    @action.bound
    setRoute(data) {
        const { location, computedMatch, history, title, breadcrumbs } = data;

        this.setTitle(title);

        this.breadcrumbs = breadcrumbs;

        set(this.location, location);
        set(this.computedMatch, computedMatch);
        set(this.history, history);
    }

    @action
    setTitle(title) {
        this.title = title;

        window.document.title = this.pageTitle;
    }

    @action.bound
    push(value) {
        this.history.push(value);
    }

    @action.bound
    go(n) {
        this.history.go(n);
    }

    @action.bound
    goBack() {
        this.history.goBack();
    }

    @action.bound
    goForward() {
        this.history.goForward();
    }
}

export default new RoutingStore();
