import { observable, action, computed, autorun } from 'mobx';
import { FETCHING, DONE, ERROR, WARNING } from 'constants/requests';

import api from 'tools/api';
import i18n, { FALLBACK_LANG, SUPPORTED_LANGS } from 'tools/i18n';
import { LocalStorage as LS } from 'tools/storage';

class LocalizationStore {
    constructor() {
        autorun(() => {
            if (!this.appLang) {
                this.getAppLang();
            }
        });
    }

    @observable appLang = LS.get('lang');

    @observable isFetching;

    @action
    setLang(lang) {
        if (lang !== FALLBACK_LANG && SUPPORTED_LANGS.includes(lang)) {
            i18n.changeLanguage(lang);
        }

        this.appLang = lang;
        LS.set('lang', lang);
    }

    @action
    async getAppLang() {
        this.isFetching = FETCHING;

        try {
            const response = await api.get('/site-settings/', {
                authorization: false
            });

            const { status, ok: respOk } = response;

            if (respOk) {
                const { LANGUAGE_CODE } = await response.json();

                this.setLang(LANGUAGE_CODE.slice(0, 2));
                this.isFetching = DONE;
            } else {
                console.warn('status:', status);
                this.isFetching = WARNING;
            }
        } catch (err) {
            console.error(err);
            this.isFetching = ERROR;
        }
    }

    @computed
    get isLoaded() {
        return (
            this.isFetching === DONE ||
            (this.appLang !== undefined && this.appLang !== null)
        );
    }
}

export default new LocalizationStore();
export { LocalizationStore };
