/**
 * Hook for i18next parser
 * @param {string} i18nKey
 */

function parseTrans(i18nKey) {
    return i18nKey;
}

export default parseTrans;
