import React from 'react';
import { Interpolate } from 'react-i18next';

/**
 * Wrapper over the Trans component, for using in attributes
 * @param {string} i18nKey
 * @param {object} varsObj interpolated variables (e.g. { name: 'John' })
 */
function interpolate(i18nKey, varsObj = {}) {
    return <Interpolate i18nKey={i18nKey} {...varsObj} />;
}

export default interpolate;
