/* eslint-disable import/no-dynamic-require, react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';
import cn from 'classnames';

import './Svg.styl';

function Svg({ name, className, style, width, height, rendering, alt }) {
    return React.cloneElement(
        <InlineSVG
            src={require(`svg-inline-loader!./_images/${name}.svg`)}
            raw
        />,
        {
            className: cn('Svg', className),
            shapeRendering: rendering,
            style,
            width,
            height,
            alt
        }
    );
}

Svg.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string,
    rendering: PropTypes.oneOf([
        'optimizeSpeed',
        'geometricPrecision',
        'crispEdges',
        'auto'
    ])
};

Svg.defaultProps = {
    rendering: 'geometricPrecision',
    className: '',
    style: {},
    alt: ''
};

export default Svg;
