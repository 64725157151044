import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import cssModules from 'hoc/cssModules';
import { withTranslate, Trans, parseTrans } from 'components/Localization';
import Link from 'components/Router/Link';
import Tooltip from 'components/UI/Tooltip';

import styles from './Item.module.styl';

function UserNotificationsItem({ notification, trans, interpolate }) {
    const {
        verb,
        data_json: {
            success_count: candidatesCount,
            errors_count: errorsCount,
            // errors,
            vacancy,
            link
        }
    } = notification;

    let message;

    switch (verb) {
        case 'Import candidates': {
            message = interpolate(
                parseTrans('Загружено {{count}} кандидатов'),
                {
                    count: candidatesCount
                }
            );
            break;
        }
        case 'Import candidates to vacancy stop-list': {
            message = interpolate(
                parseTrans('Загружено {{count}} стоп-листов'),
                {
                    count: candidatesCount
                }
            );
            break;
        }
        case 'Data export to Excel is complete.': {
            message = trans('Экспорт данных завершен');
            break;
        }
        default: {
            message = '';
        }
    }

    const isVacancyLongName = vacancy ? vacancy.name.length > 70 : false;

    return (
        <div
            styleName={cn(
                'user-notifications__item',
                errorsCount && 'user-notifications__item_warning'
            )}
        >
            <div styleName="user-notifications__item-title">{message}</div>

            {vacancy && (
                <Tooltip
                    placement="left"
                    title={isVacancyLongName && vacancy.name}
                    mouseEnterDelay={0.8}
                >
                    <div styleName="user-notifications__item-text">
                        <Link to={`/vacancies/${vacancy.id}`}>
                            {isVacancyLongName
                                ? `${vacancy.name.slice(0, 68)}...`
                                : vacancy.name}
                        </Link>
                    </div>
                </Tooltip>
            )}

            {link && (
                <div styleName="user-notifications__item-text">
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <Trans>Скачать</Trans>
                    </a>
                </div>
            )}

            {/* {errors.import_file.length > 0 && (
                <div> */}
            {/* Backend is not ready */}
            {/* <button
                        type="button"
                        className={styles['user-notifications__item-conflicts-btn']}
                        onClick={() => console.log('toggle')}
                    >
                        <Trans>Конфликт при загрузке</Trans>
                    </button> */}

            {/* <Trans>Кандидатов не загружено: </Trans>
                    {errorsCount}
                </div>
            )} */}

            {vacancy &&
                vacancy.city && (
                    <div styleName="user-notifications__item-city">
                        {vacancy.city}
                    </div>
                )}

            <div styleName="user-notifications__item-date">
                {moment(notification.timestamp).format('DD.MM.YYYY, HH:mm')}
            </div>
        </div>
    );
}

UserNotificationsItem.propTypes = {
    trans: PropTypes.func.isRequired,
    interpolate: PropTypes.func.isRequired,
    notification: PropTypes.shape({
        verb: PropTypes.string.isRequired,
        data_json: PropTypes.shape({
            success_count: PropTypes.number,
            errors_count: PropTypes.number,
            vacancy: PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string
            }),
            link: PropTypes.string
        })
    }).isRequired
};

export default withTranslate(cssModules(styles)(UserNotificationsItem));
