/* eslint-disable react/no-multi-comp */

import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { bind } from 'decko';

import { LocalStorage as LS } from 'tools/storage';
import interpolate from './tools/interpolate';
import parseTrans from './tools/parseTrans';

function withTranslate(WrappedComponent) {
    class WithTranslate extends Component {
        static propTypes = {
            i18n: PropTypes.object.isRequired, // eslint-disable-line
            t: PropTypes.func.isRequired,
            forwardedRef: PropTypes.object // eslint-disable-line
        };

        static contextTypes = {
            i18n: PropTypes.object.isRequired, // eslint-disable-line
            t: PropTypes.func.isRequired
        };

        static defaultProps = {
            forwardedRef: undefined
        };

        constructor(props, context) {
            super(props, context);

            this.i18n = props.i18n || context.i18n;
            this.t = props.t || context.t;
        }

        @bind
        changeLng(lng) {
            this.i18n.changeLanguage(lng);
        }

        /**
         * Wrapper over the Trans component, for using in attributes
         * @param {string} i18nKey
         */
        @bind
        transVar(i18nKey) {
            return this.t(i18nKey);
        }

        render() {
            // TODO: it doesn't work
            // const lang = this.i18n.language;
            const lang = LS.get('lang');
            const { forwardedRef, ...rest } = this.props;

            return (
                <WrappedComponent
                    i18n={this.i18n}
                    lang={lang}
                    trans={this.t}
                    transVar={this.transVar}
                    parseTrans={parseTrans}
                    interpolate={interpolate}
                    changeLng={this.changeLng}
                    ref={forwardedRef}
                    {...rest}
                />
            );
        }
    }

    WithTranslate.displayName = `WithTranslate(${WrappedComponent.displayName ||
        WrappedComponent.name ||
        'Component'})`;

    const ComponentWithTranslate = translate('translations')(WithTranslate);

    // https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-in-higher-order-components
    return React.forwardRef((props, ref) => (
        <ComponentWithTranslate {...props} forwardedRef={ref} />
    ));
}

export default withTranslate;
