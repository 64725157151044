import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button as AntButton } from 'antd';

import './Button.styl';

// eslint-disable-next-line react/prefer-stateless-function
class Button extends Component {
    render() {
        const {
            children,
            className,
            styleName,
            size,
            tag,
            color,
            transparent,
            ...rest
        } = this.props;
        const classes = cn(
            'Button',
            className,
            styleName,
            `Button_${size}`,
            `Button_${color}`,
            { Button_transparent: transparent }
        );

        if (tag !== 'button') {
            return React.createElement(
                tag,
                { className: classes, ...rest },
                children
            );
        }

        return (
            <AntButton className={classes} {...rest}>
                {children}
            </AntButton>
        );
    }
}

Button.defaultProps = {
    className: '',
    size: 'middle',
    color: 'default',
    transparent: false,
    tag: 'button'
};

Button.propTypes = {
    className: PropTypes.string,
    styleName: PropTypes.string,
    size: PropTypes.oneOf(['large', 'small', 'middle']),
    color: PropTypes.oneOf(['ghost', 'break', 'default', 'blue']),
    transparent: PropTypes.bool,
    tag: PropTypes.oneOf(['a', 'button']),
    disabled: PropTypes.bool,
    children: PropTypes.node
};

export default Button;
