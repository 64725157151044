import React from 'react';
import { inject } from 'mobx-react';

import { routingTypes } from 'types';

function ScreensNoMatch({ routingStore }) {
    return <h1>Not found {routingStore.location.pathname}</h1>;
}

ScreensNoMatch.propTypes = {
    routingStore: routingTypes
};

export default inject('routingStore')(ScreensNoMatch);
