import React from 'react';
import PropTypes from 'prop-types';

import cssModules from 'hoc/cssModules';
import Link from 'components/Router/Link';

import styles from './Item.module.styl';

function TopbarBreadcrumbsItem({ text, link, ...props }) {
    if (link) {
        return (
            <Link styleName="breadcrumb__link" to={link} {...props}>
                {text}
            </Link>
        );
    }

    return (
        <span styleName="breadcrumb__text" {...props}>
            {text}
        </span>
    );
}

TopbarBreadcrumbsItem.propTypes = {
    text: PropTypes.string,
    link: PropTypes.string
};

export default cssModules(styles)(TopbarBreadcrumbsItem);
