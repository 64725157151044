import { observable, action, toJS } from 'mobx';
import { trans } from 'tools/i18n';
import api from 'tools/api';

class TeamsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @observable teams = [];

    @observable roles = [];

    @observable teamsTotalCount = 0;

    @action.bound
    async loadRoles() {
        try {
            const res = await api.get('/roles/');

            if (res.ok) {
                const { results } = await res.json();

                this.roles = results;
            } else {
                this.notificationStore.setMessage(
                    trans('Не удалось загрузить роли'),
                    'error'
                );
            }

            return res.ok;
        } catch (err) {
            this.notificationStore.setMessage(
                trans('Не удалось загрузить роли'),
                'error'
            );
            console.error(err);
        }

        return false;
    }

    @action.bound
    async loadTeams(params) {
        try {
            const res = await api.get('/team/', params);

            if (res.ok) {
                const { count, results } = await res.json();

                this.teams = results;
                this.teamsTotalCount = count;
            } else {
                this.notificationStore.setMessage(
                    trans('Не удалось загрузить команды'),
                    'error'
                );
            }

            return res.ok;
        } catch (err) {
            this.notificationStore.setMessage(
                trans('Не удалось загрузить команды'),
                'error'
            );
            console.error(err);
        }

        return false;
    }

    @action.bound
    async loadTeam(id) {
        try {
            const res = await api.get(`/team/${id}/`);

            if (res.ok) {
                const data = await res.json();

                this.updateTeam(id, data);
            } else {
                this.notificationStore.setMessage(
                    trans('Не удалось загрузить команду'),
                    'error'
                );
            }

            return res.ok;
        } catch (err) {
            this.notificationStore.setMessage(
                trans('Не удалось загрузить команду'),
                'error'
            );
            console.error(err);
            return err;
        }
    }

    @action.bound
    async patchTeam(id, params) {
        try {
            const res = await api.patch(`/team/${id}/`, params);

            if (res.ok) {
                const data = await res.json();

                this.updateTeam(id, data);
            } else {
                this.notificationStore.setMessage(
                    trans('Не удалось обновить команду'),
                    'error'
                );
            }

            return res.ok;
        } catch (err) {
            this.notificationStore.setMessage(
                trans('Не удалось обновить команду'),
                'error'
            );
            console.error(err);
            return err;
        }
    }

    updateTeam(id, data) {
        const index = this.getTeamIndex(id);
        const teams = toJS(this.teams);

        this.teams = [
            ...teams.slice(0, index),
            { ...teams[index], ...data },
            ...teams.slice(index + 1)
        ];
    }

    @action.bound
    async addTeam(name) {
        try {
            const res = await api.post('/team/', { name });

            if (res.ok) {
                this.loadTeams();
            } else {
                this.notificationStore.setMessage(
                    trans('Не удалось добавить команду'),
                    'error'
                );
            }

            return res.ok;
        } catch (err) {
            this.notificationStore.setMessage(
                trans('Не удалось добавить команду'),
                'error'
            );
            console.error(err);
            return false;
        }
    }

    @action.bound
    async inviteMember({ team, email }) {
        try {
            const res = await api.post('/team/invite/', { team, email });

            if (!res.ok) {
                this.notificationStore.setMessage(
                    trans('Не удалось отправить приглашение'),
                    'error'
                );
            }

            return res.ok;
        } catch (err) {
            this.notificationStore.setMessage(
                trans('Не удалось отправить приглашение'),
                'error'
            );
            console.error(err);
            return false;
        }
    }

    @action.bound
    async removeTeam(id) {
        try {
            const res = await api.delete(`/team/${id}/`);

            if (res.ok) {
                this.loadTeams();
            } else {
                this.notificationStore.setMessage(
                    trans('Не удалось удалить команду'),
                    'error'
                );
            }

            return res.ok;
        } catch (err) {
            this.notificationStore.setMessage(
                trans('Не удалось удалить команду'),
                'error'
            );
            console.error(err);
            return false;
        }
    }

    @action.bound
    async moveMember(memberId, teamId) {
        const opts = { profile: memberId };

        if (teamId) {
            opts.team = parseInt(teamId, 10);
        }

        try {
            const res = await api.post('/team/transfer/', opts);

            if (!res.ok) {
                this.notificationStore.setMessage(
                    trans('Не удалось переместить'),
                    'error'
                );
                return false;
            }

            this.loadTeams();

            return true;
        } catch (err) {
            this.notificationStore.setMessage(
                trans('Не удалось переместить'),
                'error'
            );
            console.error(err);
            return false;
        }
    }

    @action.bound
    async removeMember(memberId, teamId) {
        const opts = { profile: memberId };

        try {
            const res = await api.post('/team/transfer/', opts);

            if (!res.ok) {
                this.notificationStore.setMessage(
                    trans('Не удалось удалить'),
                    'error'
                );
                return false;
            }

            this.loadTeam(teamId);

            return true;
        } catch (err) {
            this.notificationStore.setMessage('Не удалось удалить', 'error');
            console.error(err);
            return false;
        }
    }

    @action.bound
    async updateMemberRole(userId, role, teamId) {
        const opts = {
            role
        };

        try {
            const res = await api.post(`/user/${userId}/roles/`, opts);

            if (!res.ok) {
                this.notificationStore.setMessage(
                    trans('Не удалось поменять роль'),
                    'error'
                );
                return false;
            }

            this.loadTeam(teamId);

            return true;
        } catch (err) {
            this.notificationStore.setMessage(
                trans('Не удалось поменять роль'),
                'error'
            );
            console.error(err);
            return false;
        }
    }

    getTeamIndex(id) {
        let index = -1;

        this.teams.some((team, i) => {
            if (team.id === parseInt(id, 10)) {
                index = i;
                return true;
            }

            return false;
        });

        return index;
    }

    get notificationStore() {
        return this.rootStore.notificationStore;
    }
}

export default new TeamsStore();
export { TeamsStore };
