import React from 'react';
import { Tooltip as AntTooltip } from 'antd';
import PropTypes from 'prop-types';

function Tooltip({ children, mouseEnterDelay = 0.5, ...props }) {
    return (
        <AntTooltip
            className="Tooltip"
            overlayClassName="Tooltip__overlay"
            mouseEnterDelay={mouseEnterDelay}
            mouseLeaveDelay={0}
            {...props}
        >
            {children}
        </AntTooltip>
    );
}

Tooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
        .isRequired,
    mouseEnterDelay: PropTypes.number
};

export default Tooltip;
