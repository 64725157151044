import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { bind } from 'decko';

import cssModules from 'hoc/cssModules';
import Spinner from 'components/UI/Spinner';
import UserNotificationsItem from './Item';

import styles from './Menu.module.styl';

@inject(stores => ({ userStore: stores.rootStore.userStore }))
@observer
@cssModules(styles)
class UserNotificationsMenu extends Component {
    static propTypes = {
        userStore: PropTypes.shape({
            userNotifications: PropTypes.shape({
                count: PropTypes.number.isRequired
            }).isRequired
        }).isRequired,
        onClose: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.userStore = props.userStore;
    }

    @bind
    handleScroll() {
        const scrollValues = this.scrolEl.getValues();

        const {
            userNotifications: { isFetching, next },
            getUserNotifications
        } = this.userStore;

        // request data if scrolled more than 80% and there is the next data
        if (Math.floor(scrollValues.top * 100) >= 80 && next && !isFetching) {
            getUserNotifications();
        }
    }

    render() {
        const {
            userNotifications: { count, results }
        } = this.userStore;

        const { onClose, title } = this.props;

        return (
            <div styleName="user-notifications">
                <button
                    type="button"
                    styleName="user-notifications__close"
                    onClick={onClose}
                />

                <div styleName="user-notifications__title">
                    {title}

                    <span styleName="user-notifications__title-count">
                        {count}
                    </span>
                </div>

                {count > 0 && (
                    <div styleName="user-notifications__list">
                        <Scrollbars
                            autoHeight
                            autoHeightMin={50}
                            autoHeightMax={300}
                            renderTrackVertical={({ style, ...props }) => (
                                <div
                                    style={{ ...style }}
                                    className={
                                        styles['user-notifications__track']
                                    }
                                    {...props}
                                />
                            )}
                            renderThumbVertical={({ style, ...props }) => (
                                <div
                                    style={{ ...style }}
                                    className={
                                        styles[
                                            'user-notifications__track-thumb'
                                        ]
                                    }
                                    {...props}
                                />
                            )}
                            onScroll={this.handleScroll}
                            ref={node => {
                                this.scrolEl = node;
                            }}
                        >
                            {results.length > 0 ? (
                                results.map(notification => (
                                    <UserNotificationsItem
                                        key={notification.timestamp}
                                        notification={notification}
                                    />
                                ))
                            ) : (
                                <Spinner />
                            )}
                        </Scrollbars>
                    </div>
                )}
            </div>
        );
    }
}

export default UserNotificationsMenu;
