import { parseTrans } from 'components/Localization';

const CANDIDATE_ACTIONS = [
    {
        name: 'phoneInterview',
        value: parseTrans('Телефонное интервью'),
        status: 39,
        invite_status: 2,
        interview_status: 0
    },
    {
        name: 'invite',
        value: parseTrans('Пригласить на собеседование'),
        status: 34,
        invite_status: 2,
        interview_status: 0
    },
    {
        name: 'interviewed',
        value: parseTrans('Собеседование состоялось'),
        status: 40,
        invite_status: 2,
        interview_status: 0
    },
    {
        name: 'hire',
        value: parseTrans('Принять на работу'),
        status: 35,
        invite_status: 2,
        interview_status: 1
    },
    {
        name: 'reject',
        value: parseTrans('Отклонить'),
        status: 36,
        invite_status: 4,
        interview_status: 0
    }
];

/**
 * return actions values by names
 */
function getCandidateActionsNames([...actionNames]) {
    return [...actionNames].map(
        actionName =>
            CANDIDATE_ACTIONS.find(action => action.name === actionName).value
    );
}

const CANDIDATE_STATUSES = {
    '0': {
        key: 'STOP_LIST',
        text: parseTrans('В стоп-листе вакансии')
    },
    '1': {
        key: 'NEW',
        text: parseTrans('Еще нет результата')
    },
    '31': {
        key: 'POSITIVE_RESPONSE',
        text: parseTrans('Отклик')
    },
    '32': {
        key: 'SAIDNO_ANSWER',
        text: parseTrans('Отказ')
    },
    '34': {
        key: 'INTERVIEW',
        text: parseTrans('Назначено собеседование')
    },
    '35': {
        key: 'HIRED',
        text: parseTrans('Принят')
    },
    '36': {
        key: 'REJECTED',
        text: parseTrans('Отклонен рекрутером')
    },
    '38': {
        key: 'VIDEO_ITERVIEW',
        text: parseTrans('Видео-интервью')
    },
    '39': {
        key: 'PHONE_INTERVIEW',
        text: parseTrans('Телефонное интервью')
    },
    '40': {
        key: 'INTERVIEW_DONE',
        text: parseTrans('Собеседование состоялось')
    },
    '41': {
        key: 'CALL_ACCEPTED',
        text: parseTrans('Звонок состоялся')
    },
    '200': {
        key: 'NO_CONTACT',
        text: parseTrans('Не смогли связаться')
    }
};

/**
 * return status texts by keys
 */
function getCandidateStatusText([...statusKeys]) {
    return [...statusKeys].map(
        statusKey => CANDIDATE_STATUSES[`${statusKey}`].text
    );
}

const CANDIDATE_SOURCES = {
    hh: parseTrans('HeadHunter робот'),
    hh_ext: parseTrans('HeadHunter расширение'),
    sj: parseTrans('SuperJob робот'),
    sj_hot: parseTrans('SuperJob теплый отклик'),
    zarplata: parseTrans('zarplata.ru робот'),
    rabotaru: parseTrans('rabota.ru робот'),
    rabotaru_ext: parseTrans('rabota.ru расширение'),
    'job-mo_ext': parseTrans('Job-MO.ru расширение'),
    own_resume: parseTrans('Добавлены самостоятельно'),
    income_call: parseTrans('Входящий звонок'),
    landing: parseTrans('Посадочная страница')
};

const INVITE_TEXT = parseTrans(
    'Алексей, спасибо за то, что уделили нам время. Ваш опыт показался нам очень интересным. Мы хотели бы пообщаться с вами в нашем офисе и пригласить вас в понедельник, %18 августа%, в 11:00.\n\nВ том случае, если вам не подходит текущая дата и время, просьба связаться с нами в ответном письме или по телефону\n+7 (999) 999-99-99'
);

const REFUSAL_TEXT = parseTrans(
    'Большое спасибо за Ваш интерес, проявленный к нашей открытой вакансии.\nК сожалению, в настоящий момент мы не готовы сделать Вам это предложение. Мы внимательно ознакомились с Вашим резюме, и, возможно, вернемся к Вашей кандидатуре, когда у нас возникнет такая потребность.'
);

const RESPONSE_STATUSES = ['31', '34', '35', '36', '38', '39', '40'];
const RESPONSE_SOURCES = [
    'own_resume',
    'hh_ext',
    'sj',
    'sj_hot',
    'rabotaru',
    'zarplata',
    'stop_list',
    'income_call',
    'landing'
];

export {
    CANDIDATE_SOURCES,
    CANDIDATE_STATUSES,
    INVITE_TEXT,
    REFUSAL_TEXT,
    CANDIDATE_ACTIONS,
    getCandidateActionsNames,
    getCandidateStatusText,
    RESPONSE_STATUSES,
    RESPONSE_SOURCES
};
