import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Avatar } from 'antd';

import { authTypes, userDataTypes } from 'types';

import cssModules from 'hoc/cssModules';
import { withTranslate, Trans } from 'components/Localization';
import Spinner from 'components/UI/Spinner';
import TopbarUserNotifications from './UserNotifications';
import Impersonate from './Impersonate';

import TopbarMenu from './Menu';
import TopbarBreadcrumbs from './Breadcrumbs';

import styles from './Topbar.module.styl';

@withTranslate
@inject(({ authStore, rootStore: { userStore, appStore: { settings } } }) => ({
    settings,
    authStore,
    userStore
}))
@observer
@cssModules(styles)
class Topbar extends Component {
    static propTypes = {
        authStore: authTypes.isRequired,
        userStore: userDataTypes.isRequired,
        settings: PropTypes.shape({
            NEW_BILLING_PLAN: PropTypes.bool
        })
    };

    componentDidMount() {
        const {
            userStore: { getCurrentUser }
        } = this.props;

        getCurrentUser();
    }

    get UserBalance() {
        const {
            userStore: { currentUser },
            settings: { NEW_BILLING_PLAN }
        } = this.props;

        return (
            <div styleName="balance">
                <span styleName="balance-text">
                    <Trans>Баланс</Trans>:
                </span>

                {currentUser ? (
                    <span styleName="balance-count">
                        <span>
                            {NEW_BILLING_PLAN
                                ? currentUser.currencyBalance
                                : currentUser.balance}
                        </span>{' '}
                        {NEW_BILLING_PLAN ? (
                            <Trans>руб.</Trans>
                        ) : (
                            <Trans>откликов</Trans>
                        )}
                    </span>
                ) : (
                    <Spinner />
                )}
            </div>
        );
    }

    render() {
        const {
            userStore: {
                isLoaded,
                currentUser,
                userNotifications: { unreadCount }
            },
            authStore: { isLoginAs }
        } = this.props;

        return (
            <div styleName="topbar">
                <Impersonate visible={isLoginAs} user={currentUser} />

                <TopbarBreadcrumbs />

                <div styleName="topbar__right">
                    <div styleName="topbar__balances">
                        {currentUser &&
                            currentUser.roles.some(({ name }) =>
                                ['Administrator'].includes(name)
                            ) && (
                                <div styleName="balance">
                                    <span styleName="balance-text">
                                        <Trans>Общий баланс</Trans>:
                                    </span>
                                    <span styleName="balance-count">
                                        <span>
                                            {currentUser ? (
                                                currentUser.companyBalance
                                            ) : (
                                                <Spinner />
                                            )}
                                        </span>{' '}
                                        <Trans>руб.</Trans>
                                    </span>
                                </div>
                            )}

                        {this.UserBalance}
                    </div>

                    <TopbarUserNotifications unreadCount={unreadCount} />

                    <Fragment>
                        <TopbarMenu isLoaded={isLoaded} user={currentUser}>
                            <div styleName="topbar__avatar">
                                <Avatar
                                    size="large"
                                    icon="user"
                                    styleName="topbar__avatar-img"
                                />
                            </div>
                        </TopbarMenu>
                    </Fragment>
                </div>
            </div>
        );
    }
}

export default Topbar;
