import { observable, action, computed } from 'mobx';
import { trans } from 'tools/i18n';
import api from 'tools/api';

import {
    getCandidateActionsNames,
    CANDIDATE_ACTIONS
} from 'constants/candidate';
import Candidate from './models/candidateModel';

const [INVITE, REJECT] = getCandidateActionsNames(['invite', 'reject']);

class CandidateStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @observable
    currentCandidate = {
        id: '',
        email: '',
        record_url: ''
    };

    @observable isFetching = false;

    @computed
    get selectedCandidateIds() {
        return [this.currentCandidate.id];
    }

    @action.bound
    async fetchCandidate(candidateId) {
        this.isFetching = true;

        try {
            const response = await api.get(`/candidate/${candidateId}/`);
            const { status, ok: responseOk } = response;

            if (responseOk) {
                const candidate = await response.json();

                this.currentCandidate = new Candidate(candidate);
            } else {
                console.warn('status:', status);
            }

            this.isFetching = false;
        } catch (err) {
            console.error(err);
            this.isFetching = false;
        }
    }

    @action.bound
    async updateCandidateComment(
        value,
        candidateId = this.currentCandidate.id
    ) {
        this.currentCandidate.comments = value;

        try {
            const response = await api.patch(`/candidate/${candidateId}/`, {
                comments: value
            });

            if (response.ok) {
                this.currentCandidate.comments = value;
            } else {
                console.warn(response.status);
                this.rootStore.notificationStore.setMessage(
                    trans('Не удалось изменить комментарий'),
                    'error'
                );
            }
        } catch (err) {
            console.error(err);
            this.rootStore.notificationStore.setMessage(
                trans('Не удалось изменить комментарий'),
                'error'
            );
        }
    }

    @action.bound
    async changeCandidateStatus(value, id = this.currentCandidate.id) {
        const candidateAction = CANDIDATE_ACTIONS.find(
            it => it.value === value
        );

        const {
            status,
            invite_status: inviteStatus,
            invterview_status: interviewStatus
        } = candidateAction;

        try {
            const response = await api.patch(`/candidate/${id}/`, {
                status,
                invite_status: inviteStatus,
                invterview_status: interviewStatus
            });

            const { status: respStatus, ok: respOk } = response;

            if (respOk) {
                const candidate = await response.json();

                this.rootStore.candidatesStore.fetchResponses(
                    undefined,
                    undefined,
                    undefined,
                    { withSpinner: false }
                );

                // update candidate especially candidate status
                this.currentCandidate = new Candidate(candidate);

                this.rootStore.notificationStore.setMessage(
                    trans('Операция успешно выполнена'),
                    'success'
                );
            } else {
                this.rootStore.notificationStore.setMessage(
                    trans('Операция не выполнена'),
                    'warning'
                );

                console.warn('status:', respStatus);
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async rejectCandidate(message, candidateId = this.currentCandidate.id) {
        await this.changeCandidateStatus(REJECT, candidateId);

        try {
            const response = await api.post(
                `/candidate/${candidateId}/send-fail/`,
                {
                    message
                }
            );

            const { status, ok: respOk } = response;

            if (respOk) {
                this.rootStore.candidatesStore.fetchResponses();
            } else {
                console.warn(status);
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async inviteCandidate(message, candidateId = this.currentCandidate.id) {
        await this.changeCandidateStatus(INVITE, candidateId);

        try {
            const response = await api.post(
                `/candidate/${candidateId}/invite-interview/`,
                {
                    message
                }
            );

            const { status, ok: respOk } = response;

            if (respOk) {
                this.rootStore.candidatesStore.fetchResponses();
            } else {
                console.warn(status);
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    changeField(key, value) {
        this[key] = value;
    }

    @action.bound
    changeCandidateField(key, value) {
        this.currentCandidate[key] = value;
    }

    @computed
    get vacancyStore() {
        return this.rootStore.vacancyStore;
    }
}

export default new CandidateStore();
export { CandidateStore };
