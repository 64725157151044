import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import Spinner from 'components/UI/Spinner';

const Loader = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}
    >
        <Spinner size="large" />
    </div>
);

function LazyComponent({ loader, preload, visible, ...props }) {
    const LoadableComponent = Loadable({
        loader,
        loading: Loader,
        // eslint-disable-next-line react/prop-types
        render: ({ default: Component }) => <Component {...props} />
    });

    if (preload) {
        LoadableComponent.preload();

        return visible && <LoadableComponent {...props} />;
    }

    return <LoadableComponent {...props} />;
}

LazyComponent.propTypes = {
    loader: PropTypes.any, // eslint-disable-line
    preload: PropTypes.any, // eslint-disable-line
    visible: PropTypes.bool
};

export default LazyComponent;
