import { string, number, shape, arrayOf, bool, func } from 'prop-types';
import candidateDataTypes from './candidateDataTypes';

const candidatesTypes = shape({
    candidates: arrayOf(candidateDataTypes),
    isLoaded: bool,
    isFetching: string,
    totalCandidates: number,
    pageSize: number,
    candidatesPage: number,
    ordering: string,
    fetchCandidates: func,
    changeCandidatesVacancy: func,
    removeCandidates: func,
    addCandidatesToStoplist: func,
    downloadXls: func
});

export default candidatesTypes;
