import React from 'react';
import { Redirect } from 'react-router-dom';
import { trans } from 'tools/i18n';

import LazyComponent from 'components/LazyComponent';

const routes = [
    /**
     * Auth
     */
    {
        path: '/auth/login',
        private: false,
        title: trans('Вход в аккаунт'),
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Login" */ 'screens/Auth/Login')
                }
                {...props}
            />
        )
    },
    {
        path: '/auth/register',
        private: false,
        title: trans('Регистрация'),
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Register" */ 'screens/Auth/Register')
                }
                {...props}
            />
        )
    },
    {
        path: '/auth',
        private: false,
        component: () => <Redirect to="/auth/login" />,
        title: trans('Вакансии')
    },
    {
        path: '/logout',
        private: false,
        component: () => <Redirect to="/auth/login" />
    },

    {
        path: '/waitpage',
        private: false,
        title: trans('Спасибо за регистрацию!'),
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Waitpage" */ 'screens/Waitpage')
                }
                {...props}
            />
        )
    },
    {
        path: '/reset-password/:token',
        private: false,
        title: trans('Новый пароль'),
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "NewPassword" */ 'screens/Auth/NewPassword')
                }
                {...props}
            />
        )
    },
    {
        path: '/reset-password',
        private: false,
        title: trans('Восстановление пароля'),
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "ForgotPassword" */ 'screens/Auth/ForgotPassword')
                }
                {...props}
            />
        )
    },

    /**
     * User Profile
     */
    {
        path: '/profile',
        private: true,
        title: trans('Мои данные'),
        breadcrumbs: () => [{ text: trans('Мои данные') }],
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Profile" */ 'screens/Profile')
                }
                {...props}
            />
        )
    },
    {
        path: '/changepassword',
        private: true,
        title: trans('Смена пароля'),
        breadcrumbs: () => [
            { text: trans('Мои данные'), link: '/profile' },
            { text: trans('Изменение пароля') }
        ],
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "ChangePassword" */ 'screens/ChangePassword')
                }
                {...props}
            />
        )
    },

    /**
     * Vacancies
     */
    {
        path: '/vacancies/:vacancyId/stoplist',
        private: true,
        title: trans('Стоп-лист'),
        breadcrumbs: rootStore => [
            { text: trans('Вакансии'), link: '/vacancies' },
            {
                text: rootStore.vacancyStore.currentVacancy.name,
                link: `/vacancies/${rootStore.vacancyStore.currentVacancy.id}`,
                title: rootStore.vacancyStore.currentVacancy.name
            },
            {
                text: trans('Стоп-лист')
            }
        ],
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Stoplist" */ 'screens/Stoplist')
                }
                {...props}
            />
        )
    },
    {
        path: '/vacancies/:vacancyId/added-resumes',
        private: true,
        title: trans('Добавленные резюме'),
        breadcrumbs: rootStore => [
            { text: trans('Вакансии'), link: '/vacancies' },
            {
                text: rootStore.vacancyStore.currentVacancy.name,
                link: `/vacancies/${rootStore.vacancyStore.currentVacancy.id}`,
                title: rootStore.vacancyStore.currentVacancy.name
            },
            {
                text: trans('Добавленные резюме')
            }
        ],
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "AddedResumes" */ 'screens/AddedResumes')
                }
                {...props}
            />
        )
    },
    {
        path: '/vacancies/new',
        private: true,
        title: trans('Вакансии'),
        breadcrumbs: rootStore => [
            { text: trans('Вакансии'), link: '/vacancies' },
            {
                text: rootStore.vacancyStore.currentVacancy.name,
                title: rootStore.vacancyStore.currentVacancy.name
            }
        ],
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Master" */ 'screens/Master')
                }
                {...props}
            />
        )
    },
    {
        path: '/vacancies/:vacancyId',
        private: true,
        title: trans('Вакансии'),
        breadcrumbs: rootStore => [
            { text: trans('Вакансии'), link: '/vacancies' },
            {
                text: rootStore.vacancyStore.currentVacancy.name,
                title: rootStore.vacancyStore.currentVacancy.name
            }
        ],
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Vacancy" */ 'screens/Vacancy')
                }
                {...props}
            />
        )
    },
    {
        path: '/vacancies',
        private: true,
        title: trans('Вакансии'),
        breadcrumbs: () => [{ text: trans('Вакансии') }],
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Vacancies" */ 'screens/Vacancies')
                }
                {...props}
            />
        )
    },

    /**
     * Statistics
     */
    {
        path: '/statistic',
        private: true,
        title: trans('Статистика'),
        breadcrumbs: () => [{ text: trans('Статистика') }],
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Stats" */ 'screens/Stats')
                }
                {...props}
            />
        )
    },

    /**
     * Responses
     */
    {
        path: '/responses/:candidateId',
        private: true,
        title: trans('Кандидаты'),
        breadcrumbs: rootStore => [
            { text: trans('Кандидаты'), link: '/responses' },
            {
                text: rootStore.candidateStore.currentCandidate.name,
                title: rootStore.candidateStore.currentCandidate.name
            }
        ],
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Candidate" */ 'screens/Candidate')
                }
                {...props}
            />
        )
    },
    {
        path: '/responses',
        exact: true,
        private: true,
        title: trans('Отклики'),
        breadcrumbs: () => [{ text: trans('Отклики') }],
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Responses" */ 'screens/Responses')
                }
                {...props}
            />
        )
    },

    /**
     * Teams
     */
    {
        path: '/teams',
        exact: true,
        private: true,
        title: trans('Управление командой'),
        breadcrumbs: () => [{ text: trans('Управление командой') }],
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "Teams" */ 'screens/Teams')
                }
                {...props}
            />
        )
    },
    {
        path: '/activate',
        title: trans('Приглашение в команду'),
        component: props => (
            <LazyComponent
                loader={() =>
                    import(/* webpackChunkName: "ActivateMember" */ 'screens/Teams/ActivateMember')
                }
                {...props}
            />
        )
    },

    {
        path: '/',
        exact: true,
        private: true,
        component: () => <Redirect to="/vacancies" />,
        title: trans('Вакансии')
    }

    // {
    //     path: '/control',
    //     private: true,
    //     component: ScreensControl
    // },
    // {
    //     path: '/interview',
    //     private: true,
    //     component: ScreensInterview
    // },
    // {
    //     path: '/calendar',
    //     private: true,
    //     component: ScreensCalendar
    // },
];

export default routes;
