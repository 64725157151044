import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action, set } from 'mobx';
import { observer, inject } from 'mobx-react';
import cn from 'classnames';
import { Dropdown } from 'antd';

import cssModules from 'hoc/cssModules';
import { withTranslate } from 'components/Localization';
import Svg from 'components/UI/Svg';
import UserNotificationsMenu from './Menu';

import styles from './UserNotifications.module.styl';

const NOTIFICATIONS_COUNT_UPDATE_TIME = 60 * 1000;

@withTranslate
@inject(stores => ({ userStore: stores.rootStore.userStore }))
@observer
@cssModules(styles)
class TopbarUserNotifications extends Component {
    @observable isVisibleNotificationsMenu = false;

    static propTypes = {
        unreadCount: PropTypes.number.isRequired,
        userStore: PropTypes.shape({
            getUserNotifications: PropTypes.func.isRequired,
            checkUserNotificationsUnreadCount: PropTypes.func.isRequired
        }),
        trans: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.userStore = props.userStore;
    }

    componentDidMount() {
        this.setNotificationInterval();
    }

    componentWillUnmount() {
        clearInterval(this.checkNotificationsInteval);
    }

    setNotificationInterval() {
        const { checkUserNotificationsUnreadCount } = this.userStore;

        checkUserNotificationsUnreadCount();

        this.checkNotificationsInteval = setInterval(
            checkUserNotificationsUnreadCount,
            NOTIFICATIONS_COUNT_UPDATE_TIME
        );
    }

    // eslint-disable-next-line
    get notificationsMenu() {
        const { trans } = this.props;

        /**
         * In component <UserNotificationsMenu> there is an error,
         * when working with "withTranslate",
         * most likely the bug is related to the react context,
         * or a feature of the ant.desing component <Dropdown>.
         * As an option to upgrade package versions:
         * 1. react
         * 2. mobx
         * 3. ant
         * 4. i18next
         */
        return (
            <UserNotificationsMenu
                onClose={this.handleClick}
                title={trans('Уведомления')}
            />
        );
    }

    @action.bound
    handleClick() {
        const { getUserNotifications, userNotifications } = this.userStore;

        this.isVisibleNotificationsMenu = !this.isVisibleNotificationsMenu;

        if (this.isVisibleNotificationsMenu) {
            // Reset notification data
            set(userNotifications, {
                next: null,
                results: []
            });

            getUserNotifications();
        }
    }

    render() {
        const { unreadCount } = this.props;

        return (
            <div styleName="topbar__user-notifications">
                <Dropdown
                    placement="bottomRight"
                    trigger={['click']}
                    overlay={this.notificationsMenu}
                    visible={this.isVisibleNotificationsMenu}
                    onVisibleChange={this.handleClick}
                >
                    <div
                        className={cn(
                            styles.topbar__ring,
                            this.isVisibleNotificationsMenu &&
                                styles.topbar__ring_active
                        )}
                    >
                        {unreadCount > 0 && (
                            <div styleName="topbar__ring-count">
                                {unreadCount > 99 ? '99+' : unreadCount}
                            </div>
                        )}

                        <Svg name="notification" width={24} height={28} />
                    </div>
                </Dropdown>
            </div>
        );
    }
}

export default TopbarUserNotifications;
