import { observable, action, computed, set } from 'mobx';

import { FETCHING, DONE, ERROR } from 'constants/requests';
import api from 'tools/api';

const DEFAULT_CANDIDATES_COUNT = {
    totalCandidates: null,
    processedCandidates: null,
    resultedCandidates: null,
    responseCandidates: null,
    phoneInterviewCandidates: null,
    interviewCandidates: null,
    interviewDoneCandidates: null,
    hiredCandidates: null
};

async function getCandidatesByStatus(statuses, vacancyId) {
    const vacancy =
        vacancyId && vacancyId.length > 0 ? `&vacancy=${vacancyId}` : '';

    const candidatesData = await Promise.all(
        statuses.map(status =>
            api.get(
                `/candidate/?${vacancy}&limit=1${status && `&status=${status}`}`
            )
        )
    );
    const candidatesCounts = await Promise.all(
        candidatesData.map(data => data.json())
    );

    return candidatesCounts.map(candidate => candidate.count);
}

class StatsStore {
    @observable visibleStages = [];

    @observable isFetching = DONE;

    @observable vacancies = [];

    @observable searchPhrase = false;

    @observable candidatesCount = DEFAULT_CANDIDATES_COUNT;

    @action.bound
    resetStatsStoreData() {
        this.visibleStages.clear();
        this.vacancies.clear();
        set(this.candidatesCount, DEFAULT_CANDIDATES_COUNT);
    }

    @action.bound
    changeVisibleStages(value) {
        const { visibleStages } = this;

        if (visibleStages.includes(value)) {
            visibleStages.splice(visibleStages.indexOf(value), 1);
        } else {
            visibleStages.push(value);
        }
    }

    @computed
    get isLoaded() {
        return this.isFetching === DONE;
    }

    @action.bound
    async fetchVacancies() {
        try {
            const response = await api.get(
                '/vacancy/?limit=200&ordering=-modified_at'
            );

            if (response.ok) {
                const data = await response.json();

                const candidatesVacancies = data.results.map(vac => ({
                    key: vac.id,
                    value: `${vac.name} (${vac.city_vac})`
                }));

                this.vacancies = [
                    { key: '', value: 'Все вакансии' },
                    ...candidatesVacancies
                ];
            } else {
                console.warn('status:', response.status);
            }
        } catch (err) {
            console.error('error:', err);
        }
    }

    @action.bound
    async getCandidates(vacancyId) {
        this.isFetching = FETCHING;

        try {
            const [
                totalCandidates,
                newCandidates,
                positiveResponseCandidates,
                interviewCandidates,
                hiredCandidates,
                rejectedCandidates,
                videoInterviewCandidates,
                phoneInterviewCandidates,
                interviewDoneCandidates,
                noContactCandidates
            ] = await getCandidatesByStatus(
                [null, 1, 31, 34, 35, 36, 38, 39, 40, 200],
                vacancyId
            );

            const processedCandidates = totalCandidates - newCandidates;
            const resultedCandidates =
                processedCandidates - noContactCandidates;

            const responseCandidates =
                positiveResponseCandidates +
                interviewCandidates +
                hiredCandidates +
                rejectedCandidates +
                videoInterviewCandidates +
                phoneInterviewCandidates +
                interviewDoneCandidates;

            set(this.candidatesCount, {
                totalCandidates,
                processedCandidates,
                resultedCandidates,
                responseCandidates,
                phoneInterviewCandidates,
                interviewCandidates,
                interviewDoneCandidates,
                hiredCandidates
            });

            this.isFetching = DONE;
        } catch (err) {
            this.isFetching = ERROR;
            console.error(err);
        }
    }
}

export default new StatsStore();
export { StatsStore };
