/* @ts-check */
/* eslint-disable camelcase */
import { viewModel } from 'tools/mobx-utils';

@viewModel
class User {
    /**
     * Creates an instance of User.
     * @param {Object} user
     * @param {string} user.id - User id
     * @param {string} user.name - User name
     * @param {string} user.email - User email
     *
     * @param {Object} user.profile - User profile
     * @param {string} user.profile.id - User profile id
     * @param {string|number} user.profile.phone - User phone
     * @param {string} user.profile.company_name - User company_name
     * @param {string} user.profile.comp_url - User company ulr
     * @param {string} user.profile.balance_resume - User balance
     */
    constructor({ id, first_name, last_name, email, profile, roles, company }) {
        this.id = id;
        this.name = first_name;
        this.lastName = last_name;
        this.email = email;
        this.profileId = profile.id;
        this.phone = profile.phone;
        this.companyName = profile.company_name;
        this.companyUrl = profile.comp_url;
        this.balance = profile.balance_resume;
        this.currencyBalance = +profile.balance;
        this.inviteSms = profile.invite_params.sms;
        this.inviteEmail = profile.invite_params.email;
        this.invitePhone = profile.invite_params.phone;
        this.inviteText = profile.default_texts.invite;
        this.refusalText = profile.default_texts.refusal;
        this.roles = roles;
        this.companyBalance = company ? +company.balance : 0;
    }
}

export default User;
