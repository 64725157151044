import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { routingTypes } from 'types';

import cssModules from 'hoc/cssModules';
import BreadcrumbItem from './Item';

import styles from './Breadcrumbs.module.styl';

function TopbarBreadcrumbs({ rootStore, routingStore: { breadcrumbs } }) {
    return (
        <div styleName="breadcrumbs">
            {breadcrumbs(rootStore).map(breadcrumb => (
                <BreadcrumbItem
                    // unique key error so this is a strange key
                    key={`${breadcrumb.text}-${breadcrumb.link}`}
                    text={breadcrumb.text}
                    link={breadcrumb.link}
                    {...breadcrumb}
                />
            ))}
        </div>
    );
}

TopbarBreadcrumbs.propTypes = {
    routingStore: routingTypes.isRequired,
    // eslint-disable-next-line
    rootStore: PropTypes.object.isRequired
};

export default inject('rootStore', 'routingStore')(
    observer(cssModules(styles)(TopbarBreadcrumbs))
);
