import { observable, action, computed, when, set } from 'mobx';
import { FETCHING, DONE, ERROR, WARNING } from 'constants/requests';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import api from 'tools/api';
import helpers from 'tools/helpers';
import { trans } from 'tools/i18n';
import { urlRegExp } from 'constants/regExp';

import AuthStore from './authGlobalStore';
import User from './models/userModel';

const BALANCE_UPDATE_TIME = 30 * 1000; // 30s
const TEAM_ROLES = ['Administrator', 'Supervisor'];

class UserStore {
    @observable currentUser;

    @observable currentUserCopy;

    @observable isFetching;

    @observable
    userNotifications = {
        unreadCount: 0,
        count: 0,
        results: [],
        isFetching: false
    };

    constructor(rootStore) {
        when(
            () => this.isLoaded && AuthStore.isAuthorized,
            () => {
                this.checkBalanceInterval = setInterval(
                    this.checkBalance,
                    BALANCE_UPDATE_TIME
                );
            }
        );

        when(
            () => !AuthStore.isAuthorized,
            () => {
                if (this.checkBalanceInterval) {
                    clearInterval(this.checkBalanceInterval);
                }
            }
        );

        this.rootStore = rootStore;
    }

    @computed
    get hasTeamsPermissions() {
        const roles = this.currentUser ? this.currentUser.roles : [];

        return roles.some(({ name }) => TEAM_ROLES.includes(name));
    }

    @computed
    get isLoaded() {
        return this.isFetching === DONE && this.currentUser !== undefined;
    }

    @computed
    get editedUserFields() {
        const editedFields = [];

        if (this.isLoaded) {
            Object.entries(this.currentUserCopy).forEach(([key, value]) => {
                const currentUserValue = this.currentUser[key];

                // TODO: roles field is edited always, what is the problem?
                if (key !== 'roles' && currentUserValue !== value) {
                    editedFields.push(key);
                }
            });
        }

        return editedFields;
    }

    @computed
    get invalidUserFields() {
        const invalidFields = [];

        if (this.currentUserCopy) {
            Object.entries(this.currentUserCopy).forEach(([key, value]) => {
                let isValidField = true;

                switch (key) {
                    case 'phone': {
                        const phoneNumber = parsePhoneNumberFromString(
                            helpers.formatPhone(value)
                        );

                        isValidField = phoneNumber
                            ? phoneNumber.isValid()
                            : false;

                        break;
                    }

                    case 'name':
                    case 'lastName': {
                        if (
                            value.trim().length === 0 ||
                            value.trim().length > 30
                        ) {
                            isValidField = false;
                        }
                        break;
                    }
                    case 'companyName': {
                        if (
                            value.trim().length > 128 ||
                            value.trim().length === 0
                        ) {
                            isValidField = false;
                        }
                        break;
                    }
                    case 'companyUrl': {
                        if (
                            value &&
                            value.trim().length > 0 &&
                            !urlRegExp.test(value)
                        ) {
                            isValidField = false;
                        }
                        break;
                    }
                    default:
                        isValidField = true;
                }

                if (!isValidField) {
                    invalidFields.push({ [key]: value });
                }
            });
        }

        return invalidFields;
    }

    @action.bound
    changeField(key, value) {
        this[key] = value;
    }

    @action.bound
    async changePassword(newPass, acceptedPass, oldPass) {
        if (newPass.length <= 0 || acceptedPass.length <= 0 || oldPass <= 0) {
            this.notifications.setMessage(
                trans('Заполните все поля.'),
                'error'
            );
            return;
        }

        if (newPass !== acceptedPass) {
            this.notifications.setMessage(
                trans('Пароль и подтверждение пароля не совпадают.'),
                'error'
            );
            return;
        }

        try {
            const resp = await api.post(`/user/set_password/`, {
                new_password: newPass,
                old_password: oldPass
            });

            const { status, ok: respOk } = resp;

            if (respOk) {
                this.notifications.setMessage(
                    trans('Пароль успешно изменен'),
                    'success'
                );
            } else {
                this.notifications.setMessage(
                    trans(
                        'Невозможно изменить пароль. Введен некорректный текущий пароль.'
                    ),
                    'error'
                );
                console.warn('warning:', status);
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async updateUser() {
        let response = {};

        try {
            const { name, lastName } = this.currentUserCopy;

            response = await api.patch(`/user/${this.currentUser.id}/`, {
                first_name: name,
                last_name: lastName
            });

            const { status, ok: respOk } = response;

            this.getCurrentUser();

            if (!respOk) {
                console.warn('status:', status);
            }
        } catch (err) {
            console.error(err);
        }
        return response;
    }

    @action.bound
    async changeProfileInfo() {
        this.isFetching = FETCHING;

        const { phone, companyUrl, companyName } = this.currentUserCopy;
        const updatedData = {
            phone,
            comp_url: companyUrl,
            company_name: companyName
        };

        try {
            const profileResp = await this.updateProfileData(updatedData);
            const userResp = await this.updateUser();

            const { status: profileStatus, ok: profileOk } = profileResp;
            const { status: userStatus, ok: userOk } = userResp;

            if (profileOk && userOk) {
                this.notifications.setMessage(
                    trans('Данные профиля сохранены'),
                    'success'
                );
            } else {
                console.warn(profileStatus);
                console.warn(userStatus);
            }

            this.isFetching = DONE;
        } catch (err) {
            this.isFetching = ERROR;
            console.error(err);
        }
    }

    @action.bound
    async getCurrentUser() {
        this.isFetching = FETCHING;

        try {
            const resp = await api.get('/user/me/');
            const { status, ok: respOk } = resp;

            if (respOk) {
                const userData = await resp.json();

                this.currentUser = new User(userData);
                this.currentUserCopy = new User(userData);

                this.isFetching = DONE;
            } else {
                console.warn('status:', status);
                this.isFetching = WARNING;
            }
        } catch (err) {
            console.error(err);
            this.isFetching = ERROR;
        }
    }

    @action.bound
    async checkBalance() {
        try {
            const resp = await api.get('/user/me/');
            const { status, ok: respOk } = resp;

            if (respOk) {
                const { profile, company } = await resp.json();
                const { balance, companyBalance } = this.currentUser;

                if (balance !== profile.balance_resume) {
                    set(this.currentUser, { balance: profile.balance_resume });
                }

                if (company && company.balance !== companyBalance) {
                    set(this.currentUser, { companyBalance: +company.balance });
                }
            } else {
                console.warn('status:', status);
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async updateProfileData(profileData) {
        try {
            const response = await api.patch(
                `/profile/${this.currentUser.profileId}/`,
                profileData
            );
            const profileResData = await response.json();

            const { status, ok: respOk } = response;

            if (respOk) {
                return response;
            }

            console.warn('status: ', status, profileResData);
            return new Error('update profile warn');
        } catch (err) {
            console.error(err);
            return new Error('update profile error');
        }
    }

    @action
    changeCurrentUserCopy(key, value) {
        set(this.currentUserCopy, {
            [key]: value
        });
    }

    @action.bound
    async updateInviteParams(params) {
        const { phone, email, sms, refusal, invite } = params;

        try {
            const response = await api.patch(
                `/profile/${this.currentUser.profileId}/`,
                {
                    invite_phone: phone,
                    invite_email: email,
                    invite_sms: sms,
                    default_texts: {
                        invite,
                        refusal
                    }
                }
            );

            const { status, ok: respOk } = response;

            if (!respOk) {
                console.warn('status:', status);
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async getUserNotifications() {
        try {
            let response;

            set(this.userNotifications, {
                isFetching: true
            });

            if (this.userNotifications.next) {
                response = await api.get(
                    this.userNotifications.next.split(/api\/v2/)[1]
                );
            } else {
                response = await api.get('/notification/?limit=10');
            }

            const { ok: respOk, status } = response;

            if (respOk) {
                const userNotificationsData = await response.json();

                const { results, count, next } = userNotificationsData;

                const userNotifications = this.userNotifications.results.concat(
                    ...results
                );

                set(this.userNotifications, {
                    next,
                    count,
                    results: userNotifications,
                    isFetching: false
                });

                if (this.userNotifications.unreadCount > 0) {
                    this.readUserNotifications();
                }
            } else {
                console.warn(status);

                set(this.userNotifications, {
                    isFetching: false
                });
            }
        } catch (error) {
            console.error('get user notifications error: ', error);

            set(this.userNotifications, {
                isFetching: false
            });
        }
    }

    @action.bound
    async checkUserNotificationsUnreadCount() {
        try {
            const response = await api.get('/notification/unread-count/');

            const { ok: respOk, status } = response;

            if (respOk) {
                const notificationsCountData = await response.json();

                set(this.userNotifications, {
                    unreadCount: notificationsCountData.count
                });
            } else {
                console.warn(status);
            }
        } catch (error) {
            console.error('get user notifications count error: ', error);
        }
    }

    @action.bound
    async readUserNotifications() {
        try {
            const response = await api.post('/notification/read/');

            const { ok: respOk, status } = response;

            if (respOk) {
                await response.json();

                set(this.userNotifications, {
                    unreadCount: 0
                });
            } else {
                console.warn(status);
            }
        } catch (error) {
            console.error('get user notifications count error: ', error);
        }
    }

    @computed
    get notifications() {
        return this.rootStore.notificationStore;
    }
}

export default new UserStore();
export { UserStore };
