/**
 * FIXME: Dealing with Update Blocking
 *
 * React Router has a number of location-aware components that use the
 * current location object to determine what they render. By default,
 * the current location is passed implicitly to components using React's
 * context model. When the location changes, those components should
 * re-render using the new location object from the context.

 * React provides two approaches to optimize the rendering performance
 * of applications: the shouldComponentUpdate lifecycle method and the
 * PureComponent. Both block the re-rendering of components unless
 * the right conditions are met. Unfortunately, this means that React
 * Router's location-aware components can become out of sync with
 * the current location if their re-rendering was prevented.
 * https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
 * https://stackoverflow.com/questions/43661560/why-does-mobx-react-observer-break-react-router-dom-navlink-active-class?answertab=votes
 *
 * Also NavLink and Link sometimes has bug in react router 4 does not update view on link,
 * but does on refresh
 *
 * https://stackoverflow.com/questions/43895805/react-router-4-does-not-update-view-on-link-but-does-on-refresh
 *
 * https://github.com/ReactTraining/react-router/issues/6156
 */

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { bind } from 'decko';

@inject('routingStore')
@observer
class Link extends Component {
    static propTypes = {
        to: PropTypes.string.isRequired,
        className: PropTypes.string,
        activeClass: PropTypes.string,
        routingStore: PropTypes.shape({
            location: PropTypes.shape({
                pathname: PropTypes.string.isRequired
            }),
            push: PropTypes.func.isRequired
        }),
        children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
        title: PropTypes.string
    };

    static defaultProps = {
        className: '',
        activeClass: ''
    };

    constructor(props) {
        super(props);

        this.routingStore = props.routingStore;
    }

    @bind
    handleClick(ev) {
        const { to } = this.props;
        const { push } = this.routingStore;

        push(to);

        ev.preventDefault();
        ev.stopPropagation();
    }

    checkActiveClass(path) {
        const {
            location: { pathname }
        } = this.routingStore;

        return pathname.includes(path) || path === pathname;
    }

    render() {
        const {
            to: path,
            className,
            activeClass,
            children,
            title
        } = this.props;

        return (
            <a
                className={cn(className, {
                    [activeClass]: this.checkActiveClass(path)
                })}
                title={title}
                onClick={this.handleClick}
                href={path}
            >
                {children}
            </a>
        );
    }
}

export default Link;
