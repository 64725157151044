import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import cssModules from 'hoc/cssModules';

import Notification from 'components/UI/Notification';
import Svg from 'components/UI/Svg';
import styles from './Auth.module.styl';

@inject('authStore')
@observer
@cssModules(styles)
class LayoutAuth extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.array,
            PropTypes.object,
            PropTypes.element
        ])
    };

    constructor(props) {
        super(props);

        this.authRef = React.createRef();
    }

    render() {
        const { children } = this.props;

        return (
            <div styleName="auth" ref={this.authRef}>
                <div styleName="auth__logo">
                    <Svg name="auth" width={96} height={68} />
                </div>

                <div styleName="auth__wrapper">{children}</div>

                <Notification />
            </div>
        );
    }
}

export default LayoutAuth;
