import React from 'react';
import cssModules from 'react-css-modules';

import { LOGIN_AS_TOKEN } from 'constants/auth';
import { SessionStorage as SS } from 'tools/storage';

import { withTranslate, Trans } from 'components/Localization';

import styles from './Impersonate.module.styl';

function getAdminHref() {
    const currentHost = window.location.host;

    return currentHost.includes('beta') || currentHost.includes('localhost')
        ? `https://beta.robotvera.com/admin/auth/user/`
        : `https://${currentHost.split('.')[0]}.robotvera.com/admin/auth/user/`;
}

function Impersonate({ visible, user }) {
    if (visible) {
        document.querySelector('body').style.paddingTop = '32px';
    }

    return (
        visible && (
            <div styleName="topbar__impersonate">
                {user && (
                    <div styleName="topbar__impersonate-user">
                        <Trans>Вы вошли в систему под пользователем:</Trans>
                        <b>{user.email}</b>
                    </div>
                )}

                <a
                    styleName="topbar__impersonate-exit"
                    href={getAdminHref()}
                    onClick={() => {
                        SS.remove(LOGIN_AS_TOKEN);
                    }}
                >
                    <Trans>Выйти в панель администрирования</Trans>
                </a>
            </div>
        )
    );
}

export default withTranslate(
    cssModules(Impersonate, styles, { allowMultiple: true })
);
