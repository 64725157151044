import { observable, action, computed } from 'mobx';
import api from 'tools/api';
import { trans, interpolate, parseTrans } from 'tools/i18n';

import {
    CANDIDATE_ACTIONS,
    RESPONSE_SOURCES,
    RESPONSE_STATUSES
} from 'constants/candidate';
import { FETCHING, DONE, ERROR, WARNING } from 'constants/requests';

import Candidate from './models/candidateModel';

class CandidatesStore {
    constructor(rootStore) {
        this.pageSize = 25;
        this.rootStore = rootStore;
    }

    get vacancyStore() {
        return this.rootStore.vacancyStore;
    }

    get candidateStore() {
        return this.rootStore.candidateStore;
    }

    get notifications() {
        return this.rootStore.notificationStore;
    }

    @observable isFetching = DONE;

    @observable searchPhrase = '';

    @observable candidates = [];

    @observable totalCandidates = 0;

    @observable candidatesStoplist = [];

    @observable totalCandidatesStoplist = 0;

    @observable candidatesPage = 1;

    @observable candidatesPageStoplist = 1;

    @observable ordering = '-modified_at';

    @observable candidatesVacancy = '';

    @observable selectedCandidateIds = [];

    @observable candidatesVacancies = [{ key: '', value: 'Все вакансии' }];

    @observable statuses = RESPONSE_STATUSES;

    @observable sources = RESPONSE_SOURCES;

    @computed
    get isLoaded() {
        return this.isFetching === DONE && this.rootStore.userStore.isLoaded;
    }

    @computed
    get statusesQuery() {
        return this.statuses ? `&status=${this.statuses.join('&status=')}` : '';
    }

    @computed
    get sourcesQuery() {
        return this.sources ? `&source=${this.sources.join('&source=')}` : '';
    }

    @action.bound
    changeSearchPhrase(value, { searchInResponses = false }) {
        this.searchPhrase = encodeURI(value.trim());

        if (this.searchPhrase.startsWith('#')) {
            this.fetchCandidate(this.searchPhrase);
        } else if (searchInResponses) {
            this.candidatesVacancy = '';

            this.fetchResponses();
        } else {
            this.fetchCandidates();
        }
    }

    @action.bound
    selectFilterStatuses(statuses) {
        if (statuses.length) {
            this.statuses = statuses;
        } else {
            this.statuses = RESPONSE_STATUSES;
        }

        this.fetchResponses();
    }

    @action.bound
    selectFilterSources(sources) {
        if (sources.length) {
            this.sources = sources;
        } else {
            this.sources = RESPONSE_SOURCES;
        }

        this.fetchResponses();
    }

    @action.bound
    async removeCandidate(candidateId, count) {
        this.isFetching = FETCHING;

        try {
            const response = await api.delete(`/candidate/${candidateId}/`);
            const { status, ok: respOk } = response;

            if (respOk) {
                this.notifications.setMessage(
                    interpolate(
                        parseTrans('Успешно удалено {{count}} резюме'),
                        { count }
                    ),
                    'success'
                );

                this.fetchCandidates();
                this.isFetching = DONE;
            } else {
                this.notifications.setMessage(
                    trans('Не удалось удалить.'),
                    'warning'
                );

                console.warn('status:', status);
                this.isFetching = WARNING;
            }
        } catch (err) {
            console.error(err);
            this.isFetching = ERROR;
        }
    }

    @action.bound
    async removeCandidates(selectedKeys) {
        this.isFetching = FETCHING;

        try {
            await Promise.all(
                selectedKeys.map(id =>
                    this.removeCandidate(id, selectedKeys.length)
                )
            );

            this.isFetching = DONE;
        } catch (err) {
            console.error(err);
            this.notifications.setMessage(
                trans('Произошла ошибка при удалении.'),
                'error'
            );
            this.isFetching = ERROR;
        }
    }

    @action.bound
    async removeStoplistCandidate(candidateId, count) {
        this.isFetching = FETCHING;

        try {
            const response = await api.delete(
                `/vacancy-stop-list/${candidateId}/`
            );
            const { status, ok: respOk } = response;

            if (respOk) {
                this.notifications.setMessage(
                    interpolate(
                        parseTrans('Успешно удалено {{count}} резюме'),
                        { count }
                    ),
                    'success'
                );

                this.fetchCandidatesStoplist();
                this.isFetching = DONE;
            } else {
                this.notifications.setMessage(
                    trans('Не удалось удалить.'),
                    'warning'
                );

                console.warn('status:', status);
                this.isFetching = WARNING;
            }
        } catch (err) {
            console.error(err);
            this.isFetching = ERROR;
        }
    }

    @action.bound
    async removeStoplistCandidates(selectedKeys) {
        this.isFetching = FETCHING;

        try {
            await Promise.all(
                selectedKeys.map(id =>
                    this.removeStoplistCandidate(id, selectedKeys.length)
                )
            )
                .then(() => {
                    this.isFetching = DONE;
                })
                .catch(err => {
                    console.error(err);
                    this.isFetching = ERROR;
                });
        } catch (err) {
            this.notifications.setMessage(
                trans('Произошла ошибка при удалении.'),
                'error'
            );
            this.isFetching = ERROR;
        }
    }

    /*
        TODO: Поправить поведение этой функции
        1) Если приходит с сервера {ok: false} то пользователь никак об
        этом не узнает. И isFetching остается fetching
        2) Разобраться зачем дополнительный блок IF:
        # if (json) {}
        в каких случаях его может не быть.
    */
    @action.bound
    async importCandidates(vacancyId, rows, importFile) {
        this.isFetching = FETCHING;

        try {
            const candidate = {
                rows,
                import_file: importFile
            };
            const response = await api.post(
                `/vacancy/${vacancyId}/candidates/`,
                candidate,
                {
                    contentType: 'multipart/form-data'
                }
            );
            const { ok: respOk, status } = response;

            if (respOk) {
                const json = await response.json();

                if (json) {
                    this.isFetching = DONE;

                    this.notifications.setMessage(
                        trans(
                            'Загрузка началась. Мы сообщим по завершении импорта.'
                        ),
                        'success'
                    );

                    this.fetchCandidates();
                } else {
                    this.isFetching = WARNING;

                    console.warn('ErrorStatus', status);
                }
            }
        } catch (err) {
            console.error('importCandidates', err);

            this.isFetching = ERROR;
        }
    }

    /*
        TODO: Поправить поведение этой функции.
        Смотри туду комментарий <importCandidates>
    */
    @action.bound
    async importStoplistCandidates(vacancyId, rows, importFile) {
        this.isFetching = FETCHING;

        try {
            const candidate = {
                rows,
                import_file: importFile
            };
            const response = await api.post(
                `/vacancy-stop-list/${vacancyId}/candidates/`,
                candidate,
                {
                    contentType: 'multipart/form-data'
                }
            );
            const { ok: respOk, status } = response;

            if (respOk) {
                const json = await response.json();

                if (json) {
                    this.notifications.setMessage(
                        trans(
                            'Загрузка началась. Мы сообщим по завершении импорта.'
                        ),
                        'success'
                    );

                    this.isFetching = DONE;
                    this.fetchCandidatesStoplist();
                } else {
                    this.isFetching = WARNING;

                    console.warn('ErrorStatus', status);
                }
            }
        } catch (err) {
            console.error('importStoplistCandidates', err);

            this.isFetching = ERROR;
        }
    }

    @action.bound
    async importCompanyStoplist(rows, importFile) {
        const candidate = {
            rows,
            import_file: importFile
        };

        try {
            const response = await api.post(
                '/company-stop-list/candidates/',
                candidate,
                {
                    contentType: 'multipart/form-data'
                }
            );
            const { ok: respOk, status } = response;

            if (respOk) {
                this.notifications.setMessage(
                    trans('Операция выполнена успешно.'),
                    'success'
                );
            } else {
                this.notifications.setMessage(
                    trans('Произошла ошибка при добавлении.'),
                    'error'
                );
                console.warn('ErrorStatus', status);
            }
        } catch (err) {
            console.error('importCompanyStoplist', err);
            this.notifications.setMessage(
                trans('Произошла ошибка при добавлении.'),
                'error'
            );
        }
    }

    @action.bound
    async candidateToStoplist(candidateId, count) {
        this.isFetching = FETCHING;

        try {
            const response = await api.post(
                `/vacancy-stop-list/${candidateId}/`
            );
            const { status, ok: respOk } = response;

            if (respOk) {
                this.notifications.setMessage(
                    trans(`Успешно добавлено ${count} резюме`),
                    'success'
                );
                this.fetchCandidates();
                this.isFetching = DONE;
            } else {
                this.notifications.setMessage(
                    trans('Не удалось добавить.'),
                    'warning'
                );
                console.warn('status:', status);
                this.isFetching = WARNING;
            }
        } catch (err) {
            console.error(err);
            this.isFetching = ERROR;
        }
    }

    @action.bound
    async addCandidatesToStoplist(selectedKeys) {
        this.isFetching = FETCHING;

        try {
            await Promise.all(
                selectedKeys.map(id =>
                    this.candidateToStoplist(id, selectedKeys.length)
                )
            );

            this.isFetching = DONE;
        } catch (err) {
            console.error(err);

            this.notifications.setMessage(
                trans('Произошла ошибка при добавлении.'),
                'error'
            );
            this.isFetching = ERROR;
        }
    }

    @action.bound
    async fetchCandidates(
        page = 1,
        pageSize = this.pageSize,
        searchPhrase = this.searchPhrase
    ) {
        this.isFetching = FETCHING;
        this.candidatesPage = page;
        const vacId = document.location.pathname.split('/')[2];

        try {
            const response = await api.get(
                `/candidate/?limit=${pageSize}&offset=${pageSize *
                    (page -
                        1)}&search=${searchPhrase}&ordering=-modified_at&${vacId &&
                    `vacancy=${vacId}`}`
            );

            if (response.ok) {
                const data = await response.json();

                this.totalCandidates = data.count;
                this.candidates = data.results.map(
                    candidateData => new Candidate(candidateData)
                );

                this.isFetching = DONE;
            } else {
                console.warn('status:', response.status);
                this.isFetching = WARNING;
            }
        } catch (err) {
            console.error('error:', err);
            this.isFetching = ERROR;
        }
    }

    @action.bound
    async fetchResponses(
        page = 1,
        pageSize = this.pageSize,
        searchPhrase = this.searchPhrase,
        options = { withSpinner: true }
    ) {
        const { withSpinner } = options;

        this.fetchVacancies();
        this.candidatesPage = page;

        if (withSpinner) {
            this.isFetching = FETCHING;
        }

        const search = searchPhrase ? `&search=${searchPhrase}` : '';
        const ordering = this.ordering ? `&ordering=${this.ordering}` : '';
        const vacancy = this.candidatesVacancy
            ? `&vacancy=${this.candidatesVacancy}`
            : '';

        try {
            const response = await api.get(
                `/candidate/?limit=${pageSize}&offset=${pageSize *
                    (page - 1)}${search}${ordering}${this.statusesQuery}${
                    this.sourcesQuery
                }${vacancy}`
            );

            if (response.ok) {
                const responsesData = await response.json();

                this.totalCandidates = responsesData.count;
                this.candidates = responsesData.results.map(
                    candidateData => new Candidate(candidateData)
                );

                this.isFetching = DONE;
            } else {
                console.warn('status:', response.status);
                this.isFetching = WARNING;
            }
        } catch (err) {
            console.error('error:', err);
            this.isFetching = ERROR;
        }
    }

    @action.bound
    changeOrdering(type) {
        const isItFullType = ['+', '-'].includes(type.slice(0, 1));

        if (isItFullType) {
            this.ordering = type;
        } else {
            this.ordering =
                this.ordering === `+${type}` ? `-${type}` : `+${type}`;
        }

        this.fetchResponses();
    }

    /**
     * TODO: Добавить механизм изменения флагов isFetching
     *
     * Method creates export xls and notification for this
     * @param {String} date - from what date need export xls
     * @param {Object} options - options for export:
     * isResponsePage - export on response page
     */
    @action.bound
    async downloadXls(date, options) {
        let dateParam = `created_at__gte=${date}%2000%3A00%3A00`;
        const vacancyParam = this.candidatesVacancy
            ? `&vacancy=${this.candidatesVacancy}`
            : '';
        let filterParam = '';

        if (options && options.isResponsePage) {
            filterParam = `${this.statusesQuery}${this.sourcesQuery}`;
            dateParam = `feedback_at__gte=${date}%2000%3A00%3A00`;
        }

        try {
            const response = await api.get(
                `/candidate/export/?${dateParam}${vacancyParam}${filterParam}`
            );
            const { status, ok: respOk } = response;

            if (respOk) {
                await response.json();

                this.notifications.setMessage(
                    trans(
                        'Экспорт данных начался. По завершении процесса мы пришлем ссылку на скачивание файла в уведомлении. Файл будет доступен для скачивания в течение последующих 30 дней.'
                    ),
                    'success',
                    6000
                );
            } else {
                this.notifications.setMessage(
                    trans('Произша ошибка'),
                    'warning'
                );
                console.warn('status:', status);
            }
        } catch (err) {
            this.notifications.setMessage(trans('Произша ошибка'), 'error');
            console.error(err);
        }
    }

    @action.bound
    async fetchCandidate(candidateId) {
        this.isFetching = FETCHING;

        try {
            const resp = await api.get(`/candidate/${candidateId.slice(1)}/`);
            const { status, ok: respOk } = resp;

            if (respOk) {
                const candidate = await resp.json();

                this.totalCandidates = 1;
                this.candidates = [new Candidate(candidate)];
                this.isFetching = DONE;
            } else {
                console.warn('status:', status);
                this.isFetching = WARNING;
            }
        } catch (err) {
            console.error(err);
            this.isFetching = ERROR;
        }
    }

    @action.bound
    async fetchCandidatesStoplist(page = 1, pageSize = this.pageSize) {
        this.candidatesStoplistPage = page;
        this.isFetching = FETCHING;
        const vacancyId = document.location.pathname.split('/')[2];

        try {
            const response = await api.get(
                `/vacancy-stop-list/?vacancy=${vacancyId}&limit=${pageSize}&offset=${pageSize *
                    (page - 1)}`
            );

            if (response.ok) {
                const data = await response.json();

                this.totalCandidatesStoplist = data.count;
                this.candidatesStoplist = data.results.map(
                    candidateData => new Candidate(candidateData)
                );

                this.isFetching = DONE;
            } else {
                console.warn('status:', response.status);
                this.isFetching = WARNING;
            }
        } catch (err) {
            console.error('error:', err);
            this.isFetching = ERROR;
        }
    }

    @action.bound
    changeCandidatesVacancy(vacancyId) {
        this.candidatesVacancy = vacancyId;
    }

    /*
        TODO: отрефакторить метод:
        1) Запилить все через Promise.all
        2) Добавить флаги isFetching
        3) Написать для него тесты
    */
    @action.bound
    changeCandidateStatus(value) {
        const candidateAction = CANDIDATE_ACTIONS.find(
            it => it.value === value
        );
        // eslint-disable-next-line camelcase
        const { status, invite_status, interview_status } = candidateAction;

        this.selectedCandidateIds.map(async id => {
            try {
                const response = await api.patch(`/candidate/${id}/`, {
                    status,
                    invite_status,
                    interview_status
                });

                if (response.ok) {
                    this.fetchResponses(
                        this.candidatesPage,
                        undefined,
                        undefined,
                        { withSpinner: false }
                    );
                    this.notifications.setMessage(
                        trans('Операция успешно выполнена'),
                        'success'
                    );

                    this.selectedCandidateIds = [];
                } else {
                    this.notifications.setMessage(
                        trans('Операция не выполнена'),
                        'warning'
                    );
                }
            } catch (err) {
                console.error(err);
            }
        });
    }

    @action.bound
    rejectCandidate(message) {
        const candidateId = this.selectedCandidateIds[0];

        this.candidateStore.rejectCandidate(message, candidateId);
    }

    @action.bound
    inviteCandidate(message) {
        const candidateId = this.selectedCandidateIds[0];

        this.candidateStore.inviteCandidate(message, candidateId);
    }

    @action.bound
    changeField(key, value) {
        this[key] = value;
    }

    @action
    async fetchVacancies() {
        try {
            const response = await api.get(
                '/vacancy/?limit=200&ordering=-modified_at'
            );

            if (response.ok) {
                const data = await response.json();

                const vacancies = data.results.map(vac => ({
                    key: vac.id,
                    value: `${vac.name} (${vac.city_vac})`
                }));
                this.candidatesVacancies = [
                    { key: '', value: 'Все вакансии' },
                    ...vacancies
                ];
            } else {
                console.warn('status:', response.status);
            }
        } catch (err) {
            console.error('error:', err);
        }
    }
}

export { CandidatesStore };

export default new CandidatesStore();
