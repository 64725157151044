/* @ts-check */
/* eslint-disable camelcase */

import moment from 'moment';
import { viewModel } from 'tools/mobx-utils';

@viewModel
class Vacancy {
    /**
     * Creates an instance of vacancy.
     * @param {Object} vacancy
     *
     * @param {String} vacancy.id - Vacancy id
     * @param {String} vacancy.name - Vacancy title
     * @param {String} vacancy.adres - Vacancy adress
     * @param {String} vacancy.desc - Vacancy description
     * @param {String} vacancy.city_vac - Vacancy city
     *
     * @param {String} vacancy.sender
     * @param {String} vacancy.subject
     * @param {String} vacancy.text
     * @param {Number} vacancy.state - Vacancy status
     * @param {Boolean} vacancy.is_incoming - modified vacancy status on "Входящие"
     *
     * @param {Object} vacancy.statistic - Statistics about vacancy
     *
     * @param {Object} vacancy.talk_tree - Tree for call
     * @param {Object} vacancy.interview_tree - Tree for video interview
     * @param {Array}  vacancy.dialog - Dialog with candidate
     *
     * @param {Boolean} vacancy.forbid_foreigns
     *
     * @param {Boolean} vacancy.launch_calls
     * @param {Boolean} vacancy.launch_emails
     * @param {Boolean} vacancy.launch_search
     *
     * @param {Date}    vacancy.modified_at
     * @param {Date}    vacancy.created_at
     * @param {Boolean} vacancy.public_on_landing
     * @param {String|Number} vacancy.redirect_tel
     *
     * @param {Number} vacancy.salary_end
     * @param {Number} vacancy.salary_start
     *
     * @param {String} vacancy.schedule
     * @param {String} vacancy.scheduled_hour
     *
     * @param {String} vacancy.search_hh
     * @param {String} vacancy.search_ra
     * @param {String} vacancy.search_sj
     * @param {String} vacancy.search_zp
     *
     * @param {String} vacancy.work_conditions
     * @param {String} vacancy.work_duties
     * @param {String} vacancy.work_experience
     * @param {String} vacancy.work_requirements
     *
     * @param {Number} vacancy.speed - Voice speed setting
     * @param {String} vacancy.emotion - Voice emotion setting
     * @param {String} vacancy.speaker - Voice speaker setting
     * @param {String} vacancy.ask_repeat - Repeat phrase in voice setting
     *
     * @param {String} vacancy.user - user data
     *
     */
    constructor({
        id,
        name = '',

        subject,
        text,

        /**
         * State list:
         * 1 - Черновик
         * 2 - В ожидании модерации
         * 3 - На модерации
         * 4 - Отклонена на модерации
         * 5 - В работе
         * 6 - Завершена
         * 7 - Изменена
         * 8 - Прошла модерацию
         */
        state = 1,
        is_incoming = false,

        talk_tree,
        interview_tree,
        dialog = [],

        // prettier-ignore
        statistic = {
            candidates: 0,
            candidates_positive_response: 0
        },

        adres,
        desc,
        city_vac = '',
        modified_at,
        created_at,
        redirect_tel = '',

        // Flags
        forbid_foreigns,
        launch_calls = true,
        launch_emails = true,
        launch_search = true,
        is_video_interview = false,
        public_on_landing,

        salary_end = '',
        salary_start = '',

        schedule = 0,
        scheduled_hour,

        search_hh,
        search_ra,
        search_sj,
        search_zp,

        work_conditions = '',
        work_duties = '',
        work_experience,
        work_requirements = '',

        speed = 1,
        emotion = 'neutral',
        speaker = 'alyss',
        ask_repeat,
        user
    } = {}) {
        this.id = id;
        this.key = id;
        this.name = name;
        this.city_vac = city_vac;
        this.adres = adres;
        this.desc = desc;
        // is_incoming - "Входящие"
        this.state = is_incoming ? 9 : state;
        this.redirect_tel = redirect_tel;

        this.dialog = dialog;
        this.interview_tree = interview_tree;
        this.talk_tree = talk_tree;

        this.public_on_landing = public_on_landing;
        this.forbid_foreigns = forbid_foreigns;

        this.launch_calls = launch_calls;
        this.launch_emails = launch_emails;
        this.launch_search = launch_search;
        this.is_video_interview = is_video_interview;

        this.modified_at = moment(modified_at).format('DD.MM.YY');
        this.created_at = moment(created_at).format('DD.MM.YY');

        this.salary_end = salary_end;
        this.salary_start = salary_start;

        this.schedule = +schedule;
        this.scheduled_hour = scheduled_hour;

        this.search_hh = search_hh;
        this.search_ra = search_ra;
        this.search_sj = search_sj;
        this.search_zp = search_zp;

        this.work_conditions = work_conditions || '';
        this.work_duties = work_duties || '';
        this.work_experience = work_experience;
        this.work_requirements = work_requirements || '';

        // stats
        this.statistic = statistic;

        // Voice settings
        this.speed = speed;
        this.emotion = emotion;
        this.speaker = speaker;
        this.ask_repeat = ask_repeat;

        this.subject = subject;
        this.text = text;

        this.user = user;
    }
}

export default Vacancy;
