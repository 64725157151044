import { func, shape, string, number } from 'prop-types';

const authTypes = shape({
    login: func,
    currentUser: shape({
        id: number,
        name: string,
        email: string,
        profile: shape({
            id: number,
            phone: string,
            balance_resume: number,
            company_description: string,
            comp_url: string,
            company_name: string,
            user_url: string
        }),
        profile_url: string
    })
});

export default authTypes;
