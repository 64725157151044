import { observable, action, computed } from 'mobx';

import { USER_TOKEN, LOGIN_AS_TOKEN } from 'constants/auth';
import { LocalStorage as LS, SessionStorage as SS } from 'tools/storage';
import { FETCHING, DONE, WARNING, ERROR } from 'constants/requests';
import { trans } from 'tools/i18n';
import api from 'tools/api';
import helpers from 'tools/helpers';
import routingStore from './routingGlobalStore';

export const HIDE_POPUP_DELAY = 3000;

const { getURLParam } = helpers;

class AuthGlobalStore {
    @observable
    isAuthorized =
        Boolean(SS.get(LOGIN_AS_TOKEN)) || Boolean(LS.get(USER_TOKEN));

    @observable isLoginAs = Boolean(SS.get(LOGIN_AS_TOKEN));

    @observable isTermsApproved = false;

    @observable isValidToken = true;

    @observable currentTab = 'login';

    @observable vacType = 'employeeType';

    @observable showPopupWhen = '';

    @observable isEmptyPhone = false;

    @observable isFetching = DONE;

    constructor() {
        const userToken = getURLParam(USER_TOKEN);

        if (userToken) {
            this.setLoginAsToken(userToken);
            window.history.replaceState(null, null, window.location.pathname);
        }
    }

    @computed
    get isLoaded() {
        return this.isFetching === DONE;
    }

    @action
    async authorizeUser(token) {
        LS.set(USER_TOKEN, token);
        this.isAuthorized = true;

        routingStore.push('/vacancies');
    }

    @action
    unauthorizeUser() {
        LS.remove(USER_TOKEN);
        SS.remove(LOGIN_AS_TOKEN);
        this.isAuthorized = false;
        // Redirect to authorization page
        routingStore.push('/auth/login');
    }

    @action
    setLoginAsToken(token) {
        SS.set(LOGIN_AS_TOKEN, token);
    }

    @action.bound
    handleTermsToggle() {
        this.isTermsApproved = !this.isTermsApproved;
    }

    showNotification(...args) {
        if (!this._setMessage) {
            this._setMessage = require('./rootGlobalStore').default.notificationStore.setMessage;
        }

        this._setMessage(...args);
    }

    @action.bound
    showAndHidePopup(string) {
        this.showPopupWhen = string;

        setTimeout(() => {
            this.showPopupWhen = 'hide';
        }, HIDE_POPUP_DELAY);
    }

    @action.bound
    handleTabs(value) {
        this.currentTab = value;
    }

    @action.bound
    setVacType(value) {
        this.vacType = value;
    }

    @action.bound
    setPhoneHandle(value, status) {
        this.isEmptyPhone = value;
        this.isValidPhone = status;
    }

    @action.bound
    async setNewPassword(newPassword, token) {
        if (!newPassword) {
            this.showAndHidePopup('samePass');
            return;
        }

        try {
            const resp = await api.post(
                `/reset-password/`,
                {
                    password: newPassword,
                    token
                },
                { authorization: false }
            );

            const { status, ok: respOk } = resp;
            const respData = await resp.json();

            if (respOk) {
                const { username } = respData;

                this.login(username, newPassword);
            } else {
                console.warn('warning:', status, respData);

                if (
                    respData.password &&
                    respData.password.includes('This password is too common.')
                ) {
                    this.showNotification(
                        trans('Введите более надежный пароль'),
                        'error'
                    );
                } else {
                    window.location.reload();
                    this.showNotification(trans('Произошла ошибка'), 'error');
                }
            }
        } catch (err) {
            console.error(err);
            this.isFetching = ERROR;
            this.showNotification(trans('Произошла ошибка'), 'error');
        }
    }

    @action.bound
    async resetPassword(email) {
        try {
            const resp = await api.get(
                `/reset-password/?email=${email.replace(/\+/g, '%2B')}`,
                {
                    authorization: false
                }
            );

            const { status, ok: respOk } = resp;

            if (!respOk) {
                console.warn('status:', status);
                this.showNotification(trans('Произошла ошибка'), 'error');
            }
        } catch (err) {
            console.error('error:', err);
            this.showNotification(trans('Произошла ошибка'), 'error');
        }
    }

    @action.bound
    async checkToken(token) {
        this.isFetching = FETCHING;

        try {
            const resp = await api.get(`/reset-password/check/${token}/`, {
                authorization: false
            });

            const { status, ok: respOk } = resp;
            const { result } = await resp.json();

            if (respOk) {
                this.isValidToken = result;
                this.isFetching = DONE;
            } else {
                console.warn('status', status);
                this.isFetching = WARNING;
                this.showNotification(trans('Произошла ошибка'), 'error');
            }
        } catch (err) {
            this.isFetching = ERROR;
            console.error('error:', err);
            this.showNotification(trans('Произошла ошибка'), 'error');
        }
    }

    @action.bound
    async registrate(email, phone, url) {
        this.isFetching = FETCHING;

        try {
            const resp = await api.post(
                '/sign-up/',
                {
                    email,
                    phone: phone.replace(/[\D\s]/g, ''),
                    url
                },
                {
                    authorization: false
                }
            );

            const { status, ok: respOk } = resp;
            const { token, non_field_errors: error } = await resp.json();

            if (error && error.includes('User already exists')) {
                this.showNotification(
                    trans('Пользователь с такими данными уже существует'),
                    'error'
                );

                return;
            }

            if (respOk) {
                this.authorizeUser(token);

                this.isFetching = DONE;
            } else {
                console.warn('status:', status);
                this.showNotification(trans('Произошла ошибка'), 'error');
                return;
            }
        } catch (err) {
            this.isFetching = ERROR;
            console.error('error:', err);
            this.showNotification(trans('Произошла ошибка'), 'error');
        }
    }

    @action.bound
    async login(email, password) {
        this.isFetching = FETCHING;

        try {
            const resp = await api.post(
                '/api-token-auth/',
                {
                    username: email,
                    password
                },
                {
                    authorization: false
                }
            );

            const { status, ok: respOk } = resp;
            const { non_field_errors: error, token } = await resp.json();

            if (error) {
                this.isFetching = WARNING;
                this.showNotification(
                    trans('Неверный E-mail или пароль'),
                    'error'
                );

                return;
            }

            if (respOk) {
                this.authorizeUser(token);

                this.isFetching = DONE;
            } else {
                this.isFetching = WARNING;
                this.showNotification(trans('Произошла ошибка'), 'error');
                console.warn('status:', status);
            }
        } catch (err) {
            this.isFetching = ERROR;
            this.showNotification(trans('Произошла ошибка'), 'error');
            console.error('error:', err);
        }
    }

    @action.bound
    async loginWithToken(token) {
        try {
            const resp = await api.post(
                `/api-token-check/`,
                {
                    token
                },
                {
                    authorization: false
                }
            );

            const { status, ok: respOk } = resp;

            if (respOk) {
                LS.set(USER_TOKEN, token);

                // Redirect to app
                window.location = '/profile';
            } else {
                console.warn('status:', status);
                this.showNotification(trans('Произошла ошибка'), 'error');
            }
        } catch (err) {
            console.error('error:', err);
            this.showNotification(trans('Произошла ошибка'), 'error');
        }
    }

    @action.bound
    logout() {
        try {
            api.post('/logout/', {});
        } catch (err) {
            console.error('logout errror: ', err);
        }
        const rootGlobalStore = require('./rootGlobalStore').default;

        rootGlobalStore.statsStore.resetStatsStoreData();

        if (SS.get(LOGIN_AS_TOKEN)) {
            SS.remove(LOGIN_AS_TOKEN);

            window.location.search = '';
            window.reload();
        } else {
            this.unauthorizeUser();
        }
    }
}

export default new AuthGlobalStore();
