import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as MxProvider, onError } from 'mobx-react';
import createBrowserHistory from 'history/createBrowserHistory';

import { LocalizationProvider } from 'components/Localization';
import ErrorBoundary from 'components/ErrorBoundary';
import Root from 'components/Root';
import * as stores from 'store';

// Application ENV
const isDevelopment = process.env.NODE_ENV !== 'production';

// History
const browserHistory = createBrowserHistory();

function App() {
    const appContent = (
        <LocalizationProvider>
            <MxProvider {...stores}>
                <Root isDevelopment={isDevelopment} history={browserHistory} />
            </MxProvider>
        </LocalizationProvider>
    );

    if (isDevelopment) {
        onError(error => {
            console.error(error);
        });

        return appContent;
    }

    return <ErrorBoundary>{appContent}</ErrorBoundary>;
}

ReactDOM.render(<App />, document.getElementById('app'));
