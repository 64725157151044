import { observable, action } from 'mobx';
import helpers from 'tools/helpers';

const { wait } = helpers;

class NotificationStore {
    @observable hideDelay = 3000;

    @observable isVisible = false;

    @observable value = '';

    @observable type;

    @action
    show() {
        this.isVisible = true;

        this.hideWithDelay();
    }

    // TODO: work with problem - need to refresh timeout when new notification shows
    async hideWithDelay() {
        await wait(this.hideDelay);

        this.hide();
    }

    @action.bound
    hide() {
        this.isVisible = false;
    }

    /**
     * show notification with params:
     * @param {string} value - message
     * @param {string} type - oneOf(['success', 'info', 'warning', 'error']),
     * @param {number} hideDelay - ms delay
     */
    @action.bound
    setMessage(value, type = 'info', hideDelay = 3000) {
        this.value = value;
        this.type = type;
        this.hideDelay = hideDelay;

        this.show();
    }
}

export default new NotificationStore();
export { NotificationStore };
