import { string, number, func, shape, arrayOf, bool } from 'prop-types';
import vacancyDataTypes from './vacancyDataTypes';

const vacanciesTypes = shape({
    vacancies: arrayOf(vacancyDataTypes),
    changeSearchPhrase: func,
    isFetching: bool,
    totalVacancies: number,
    fetchVacancies: func,
    pageSize: number,
    vacanciesPage: number,
    ordering: string
});

export default vacanciesTypes;
