import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import { withTranslate } from 'components/Localization';
import { Alert } from 'antd';

import './Notification.styl';

function Notification({ rootStore, transVar }) {
    const { notificationStore } = rootStore;

    const { value, type, isVisible, isTrans, hide } = notificationStore;
    const message = isTrans ? transVar(value) : value;

    return (
        isVisible && (
            <div className="Notification">
                <Alert
                    showIcon
                    // closable
                    message={message}
                    type={type}
                    afterClose={hide}
                />
            </div>
        )
    );
}

Notification.propTypes = {
    transVar: PropTypes.func.isRequired,
    rootStore: PropTypes.shape({
        notificationStore: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
            value: PropTypes.string.isRequired,
            hide: PropTypes.func.isRequired,
            type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
            isTrans: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
        }).isRequired
    })
};

export default withTranslate(inject('rootStore')(observer(Notification)));
