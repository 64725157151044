import { observable, action, computed, set } from 'mobx';

import { DONE, ERROR, WARNING, FETCHING } from 'constants/requests';

import { trans } from 'tools/i18n';
import api from 'tools/api';
import helpers from 'tools/helpers';
import Vacancy from './models/vacancyModel';

import routingStore from './routingGlobalStore';

const { getURLParam, formatPhone, sendGoal, wait } = helpers;

class VacancyStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @observable isNewVacancy = false;

    @observable isSearchFieldsValid = true;

    @observable currentVacancy = new Vacancy();

    @observable isFetching;

    @observable callsHistory = [];

    @observable invalidFields = [];

    @computed
    get isLoaded() {
        const { isLoaded } = this.rootStore.appStore;

        return (
            (isLoaded && this.isFetching === DONE) ||
            (isLoaded && this.isNewVacancy)
        );
    }

    @computed
    get isDisableSave() {
        const { state, schedule } = this.currentVacancy;

        return (
            [3, 5].includes(state) || !schedule || this.invalidFields.length > 0
        );
    }

    @computed
    get isDisableStart() {
        const { state } = this.currentVacancy;

        return [3, 4, 5].includes(state);
    }

    @action.bound
    updateInvalidFields(fields = []) {
        this.invalidFields = fields;
    }

    @action.bound
    changeInvalidFields({ field, isValid }) {
        if (!isValid) {
            this.invalidFields.push(field);
        } else {
            this.invalidFields = this.invalidFields.filter(it => it !== field);
        }
    }

    @action.bound
    toggleNewVacancy(value) {
        this.isNewVacancy = value;
    }

    @action.bound
    changeSearchFieldValidation(value) {
        this.isSearchFieldsValid = value;
    }

    @action.bound
    clearFields() {
        this.currentVacancy = new Vacancy();
    }

    @action.bound
    async createNewVacancy() {
        const name = getURLParam('vac_name');
        const city = getURLParam('vac_city');

        this.isFetching = DONE;
        if (!name || !city) {
            this.notifications.setMessage('Неверный url', 'error');
            routingStore.push('/vacancies');
            return;
        }

        this.currentVacancy = new Vacancy({
            name,
            city_vac: city,
            redirect_tel: ''
        });
    }

    @action.bound
    async saveNewVacancy() {
        const {
            name,
            city_vac: city,
            redirect_tel: redirectTel,
            dialog,
            ...vacancyFields
        } = this.currentVacancy;

        try {
            const { status, ok, data: vacancyData } = await api.post(
                '/vacancy/',
                {
                    name,
                    city_vac: city,
                    redirect_tel: formatPhone(redirectTel),
                    dialog: [],
                    ...vacancyFields
                }
            );

            if (ok) {
                this.notifications.setMessage(
                    trans('Вакансия создана успешно'),
                    'success'
                );

                routingStore.push(`/vacancies/${vacancyData.id}`);

                // dialog was created after 5secs vacancy creation so we should make additional request for the dialog
                setTimeout(() => this.updateVacancyFields(['dialog']), 6000);
            } else {
                console.warn('status:', status, vacancyData);

                this.notifications.setMessage(
                    trans('Ошибка создания вакансии'),
                    'warning'
                );
            }
        } catch (err) {
            console.error(err);

            this.notifications.setMessage(trans('Ошибка сохранения'), 'error');
        }
    }

    @action.bound
    async importVacancy(link) {
        await wait(1600);

        let response;

        try {
            response = await api.get(`/vacancy/jobboard/?url=${link}`);
            const { data: vacancyData, ok } = response;

            if (ok) {
                const {
                    adress: adres,
                    city_vac: city,
                    name_vac: name,
                    salary_end: salaryEnd,
                    salary_start: salaryStart,
                    schedule,
                    work_conditions: conditions,
                    work_duties: duties,
                    work_requirements: requirements
                } = vacancyData;
                routingStore.push(`/vacancies/new/?import=sj`);

                this.currentVacancy = new Vacancy({
                    adres,
                    city_vac: city,
                    name,
                    salary_end: salaryEnd,
                    salary_start: salaryStart,
                    schedule,
                    work_conditions: conditions,
                    work_duties: duties,
                    work_requirements: requirements
                });
            } else {
                this.notifications.setMessage(
                    trans('Ошибка импорта вакансии'),
                    'warning'
                );
            }
        } catch (err) {
            this.notifications.setMessage(
                trans('Ошибка импорта вакансии'),
                'error'
            );
        }

        return response;
    }

    @action.bound
    async getCurrentVacancy(vacancyId = this.currentVacancy.id) {
        let vacancy;
        this.isFetching = FETCHING;

        try {
            const resp = await api.get(`/vacancy/${vacancyId}/`);
            const { status, ok: respOk } = resp;

            if (respOk) {
                vacancy = await resp.json();

                this.currentVacancy = new Vacancy(vacancy);
                this.isFetching = DONE;
            } else {
                const msg =
                    status === 404
                        ? trans('Вакансия не найдена')
                        : trans('Ошибка при загрузке вакансии');
                this.isFetching = WARNING;
                console.warn('status:', status);
                routingStore.push('/vacancies');
                this.notifications.setMessage(msg, 'error');
            }
        } catch (err) {
            this.isFetching = ERROR;

            routingStore.push('/vacancies');
            this.notifications.setMessage(
                trans('Ошибка при загрузке вакансии'),
                'error'
            );
        }
        return vacancy;
    }

    /**
     *
     * @param {Array} fields
     */
    @action.bound
    async updateVacancyFields(fields, vacancyId = this.currentVacancy.id) {
        try {
            const resp = await api.get(`/vacancy/${vacancyId}/`);
            const { status, ok: respOk } = resp;

            if (respOk) {
                const vacancy = await resp.json();

                if (vacancy) {
                    const updatedFields = {};

                    fields.forEach(field => {
                        updatedFields[field] = vacancy[field];
                    });

                    this.currentVacancy = {
                        ...this.currentVacancy,
                        ...updatedFields
                    };
                }
            } else {
                console.warn('status:', status);
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async deleteVacancy() {
        sendGoal('SEND_TO_ARCHIVE');

        try {
            const response = await api.delete(
                `/vacancy/${this.currentVacancy.id}/`
            );

            if (response.ok) {
                this.notifications.setMessage(
                    trans('Вакансия перенесена в архив'),
                    'success'
                );

                routingStore.push('/vacancies');
            } else {
                console.warn('status:', response.status);

                this.notifications.setMessage(
                    trans('Ошибка переноса в архив'),
                    'warning'
                );
            }
        } catch (err) {
            console.error(err);

            this.notifications.setMessage(
                trans('Ошибка переноса в архив'),
                'error'
            );
        }
    }

    @action.bound
    async saveVacancy() {
        const {
            id,
            redirect_tel: redirectTel,
            interview_tree: interviewTree,
            ...vacancyFields
        } = this.currentVacancy;
        const jsonedTree = Array.isArray(interviewTree)
            ? JSON.stringify(interviewTree)
            : interviewTree;

        try {
            const response = await api.patch(`/vacancy/${id}/`, {
                redirect_tel: redirectTel,
                // TODO: Remove this line, after switching `interview_tree` to json
                interview_tree: jsonedTree,
                ...vacancyFields
            });

            const { status, ok: respOk } = response;

            if (respOk) {
                this.updateVacancyFields(['state']);

                this.notifications.setMessage(
                    trans('Данные по вакансии сохранены'),
                    'success'
                );
            } else {
                const vacancyData = await response.json();

                this.notifications.setMessage(
                    trans('Данные не сохранены'),
                    'warning'
                );
                console.warn('status:', status, vacancyData);
            }
        } catch (err) {
            console.error(err);

            this.notifications.setMessage(trans('Ошибка сохранения'), 'error');
        }
    }

    @action.bound
    async cancelModeration() {
        sendGoal('CANCEL_MODERATE');

        try {
            const response = await api.post(
                `/vacancy/${this.currentVacancy.id}/cancel-moderation/`
            );
            const { status, ok: respOk } = response;

            if (respOk) {
                const vacancyData = await response.json();

                this.notifications.setMessage(
                    trans('Модерация вакансии отменена'),
                    'success'
                );

                set(this.currentVacancy, { state: vacancyData.state });
            } else {
                this.notifications.setMessage(
                    trans('Операция не выполнена'),
                    'warning'
                );

                console.warn('status:', status, response);
            }
        } catch (err) {
            this.notifications.setMessage(
                trans('Операция не выполнена'),
                'error'
            );

            console.error(err);
        }
    }

    @action.bound
    async startVacancy() {
        sendGoal('START_VACANCY');

        try {
            const { ok, data } = await api.post(
                `/vacancy/${this.currentVacancy.id}/campaign/`
            );

            if (ok) {
                this.notifications.setMessage(
                    trans('Операция выполнена успешно'),
                    'success'
                );

                set(this.currentVacancy, { state: 2 });
            } else if (data.error === 'Empty user balance') {
                this.notifications.setMessage(
                    trans('Недостаточно средств для запуска кампании'),
                    'warning'
                );
            } else if (
                data.message ===
                'Hiring campaign may be launched from 8-00 am till 9-00 pm local time'
            ) {
                this.notifications.setMessage(
                    trans('Запуск доступен с 8:00 до 21:00'),
                    'warning'
                );
            } else {
                this.notifications.setMessage(
                    trans('Операция не выполнена'),
                    'warning'
                );
            }
        } catch (err) {
            this.notifications.setMessage(
                trans('Операция не выполнена'),
                'error'
            );

            console.error(err);
        }
    }

    @action.bound
    async stopVacancy() {
        const { ok } = await api.post(
            `/vacancy/${this.currentVacancy.id}/stop-campaign/`
        );

        if (ok) {
            this.notifications.setMessage(
                trans('Операция выполнена успешно'),
                'success'
            );

            set(this.currentVacancy, { state: 5 });
        } else {
            this.notifications.setMessage(
                trans('Операция не выполнена'),
                'warning'
            );
        }
    }

    @action.bound
    changeVacancyField(key, value) {
        this.currentVacancy[key] = value;
    }

    @action.bound
    changeTree(treeType, treeData, isValid = true) {
        const needToStringify =
            treeType === 'interview' && Array.isArray(treeData);
        const tree = needToStringify ? JSON.stringify(treeData) : treeData;

        set(this.currentVacancy, {
            [`${treeType}_tree`]: tree
        });

        this.changeInvalidFields({
            field: `${treeType}_tree`,
            isValid
        });
    }

    @action.bound
    changeDialogItem(index, key, value) {
        this.currentVacancy.dialog[index][key] = value;
    }

    @action.bound
    addNewDialogItem() {
        this.currentVacancy.dialog.unshift({
            answers: [],
            name: '',
            questions: []
        });
    }

    @action.bound
    deleteDialogItem(index) {
        this.currentVacancy.dialog.splice(index, 1);
    }

    /**
     * @param {Object} fields - current vacancy fields
     */
    @action.bound
    async patchVacancy(fields) {
        try {
            const response = await api.patch(
                `/vacancy/${this.currentVacancy.id}/`,
                {
                    ...fields
                }
            );

            const { status, ok: respOk } = response;

            if (!respOk) {
                console.warn('status:', status);
            }
        } catch (err) {
            this.notifications.setMessage(trans('Произошла ошибка'), 'error');

            console.error(err);
        }
    }

    @action.bound
    async sendEmail(email) {
        if (email.trim().length < 5) {
            this.notifications.setMessage(
                trans('Введите корректный e-mail'),
                'warning'
            );

            return false;
        }

        await this.patchVacancy({
            subject: this.currentVacancy.subject,
            text: this.currentVacancy.text
        });

        try {
            const response = await api.post(
                `/vacancy/${this.currentVacancy.id}/send-test-mail/`,
                {
                    email
                }
            );
            const { status, ok: respOk } = response;

            if (respOk) {
                this.notifications.setMessage(
                    trans('Письмо успешно отправлено'),
                    'success'
                );
            } else {
                this.notifications.setMessage(
                    trans('Ошибка отправки'),
                    'warning'
                );

                console.warn('status:', status, response);
            }
        } catch (err) {
            this.notifications.setMessage(trans('Ошибка отправки'), 'error');

            console.error(err);
        }

        return true;
    }

    @action.bound
    async testCall(phone, email) {
        try {
            const response = await api.post(
                `/vacancy/${this.currentVacancy.id}/make-test-call/`,
                {
                    rows: `${phone}, ${email}`
                }
            );
            const { status, ok: respOk, data } = response;

            if (respOk) {
                if (
                    data.errors.includes(
                        'Maximum number of test calls per hour exceeded'
                    )
                ) {
                    this.notifications.setMessage(
                        trans('Исчерпан лимит 20 звонков в час'),
                        'warning'
                    );
                } else {
                    this.notifications.setMessage(
                        trans('Робот скоро позвонит'),
                        'success'
                    );
                }
            } else {
                this.notifications.setMessage(
                    trans('Произошла ошибка'),
                    'warning'
                );

                console.warn('status:', status, response);
            }
        } catch (err) {
            this.notifications.setMessage(trans('Произошла ошибка'), 'error');

            console.error(err);
        }
    }

    @action.bound
    async fetchCallsHistory() {
        try {
            const response = await api.get(
                `/vacancy/${this.currentVacancy.id}/get-test-call/`
            );

            if (response.ok) {
                const historyData = await response.json();

                this.callsHistory = historyData;
            }
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * Aliases for short variables
     */

    get notifications() {
        return this.rootStore.notificationStore;
    }
}

export default new VacancyStore();
export { VacancyStore };
