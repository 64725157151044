import { Component } from 'react';
import PropTypes from 'prop-types';
import { initSentry, logException } from 'tools/sentry';

if (process.env.NODE_ENV === 'production') {
    initSentry({ version: process.env.version });

    // eslint-disable-next-line
    console.log('sentry inited');
}

class ErrorBoundary extends Component {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        children: PropTypes.any
    };

    componentDidCatch(error, errorInfo) {
        logException(error, errorInfo);
    }

    render() {
        const { children } = this.props;

        return children;
    }
}

export default ErrorBoundary;
