import { observable, action, computed, when } from 'mobx';
import { debounce } from 'decko';

import { FETCHING, DONE, ERROR, WARNING } from 'constants/requests';
import api from 'tools/api';
import MEDIA_QUERIES, { MOBILE } from 'constants/mediaQueries';

import AuthStore from './authGlobalStore';

class AppStore {
    constructor() {
        window.addEventListener('resize', this.setWindowSize);

        when(() => AuthStore.isAuthorized, () => this.fetchSettings());
    }

    @observable windowSize = window.innerWidth;

    @computed
    get pageLayout() {
        const queryPair = MEDIA_QUERIES.find(
            query => this.windowSize > query[1]
        );

        return queryPair ? queryPair[0] : MOBILE;
    }

    // Page settings
    @observable pageTitle = '';

    @action.bound
    setPageTitle(title) {
        this.pageTitle = 'Untitled page';

        if (title) {
            this.pageTitle = `RobotVera | ${title}`;
        }

        window.document.title = this.pageTitle;
    }

    @computed
    get topbarTitle() {
        const title = this.pageTitle;

        if (title.includes('|')) {
            return title.split('|')[1].trim();
        }

        return title;
    }

    // Site settings
    @observable
    settings = {
        // True if is_per_minute_billing=True
        NEW_BILLING_PLAN: false
    };

    @observable isFetching;

    @computed
    get isLoaded() {
        return this.isFetching === DONE && this.settings !== undefined;
    }

    @action
    async fetchSettings() {
        this.isFetching = FETCHING;

        try {
            const response = await api.get('/site-settings/');

            const { status, ok: respOk } = response;

            if (respOk) {
                const appSettings = await response.json();

                this.settings = appSettings;
                this.isFetching = DONE;
            } else {
                console.warn('status:', status);
                this.isFetching = WARNING;
            }
        } catch (err) {
            console.error(err);
            this.isFetching = ERROR;
        }
    }

    @action.bound
    @debounce(800)
    setWindowSize() {
        this.windowSize = window.innerWidth;
    }
}

export default new AppStore();
export { AppStore };
