/* eslint-disable no-useless-constructor, no-cond-assign, import/prefer-default-export */

import { decorate, observable } from 'mobx';

/**
 * Decarator decorator for MobX's modes,
 * which makes all available fields observable
 */
function viewModel(Model) {
    // A regular expression for finding model properties
    const regexp = /(this.([a-z_]+))/gim;

    // Make a set to get unique fields
    let fields = new Set();

    // Memoize model data
    let modelData = Model.toString();

    // Search result of the field
    let result;

    // prettier-ignore
    while (result = regexp.exec(modelData)) {
        fields.add(result[2]);
    }

    /**
     * Create collection of observable fields
     */
    let observableFields = Object.create(null);

    fields.forEach(field => {
        observableFields[field] = observable;
    });

    // Make all model fields observable
    decorate(Model, { ...observableFields });

    // Optimize memory
    result = null;
    fields = null;
    modelData = null;
    observableFields = null;

    return Model;
}

export { viewModel };
