import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

import { YANDEX_ID as ID } from 'constants/marketing';

const OPTIONS = {
    id: ID,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
};

const YandexMetrika = () => (
    <YMInitializer accounts={[ID]} options={OPTIONS} version="2" />
);

export default YandexMetrika;
