import { USER_TOKEN, LOGIN_AS_TOKEN } from 'constants/auth';
import { LocalStorage as LS, SessionStorage as SS } from 'tools/storage';
import { notification } from 'antd';

const { protocol, host } = window.location;
const isDev = host.includes('beta') || host.includes('localhost');
const isTest = host.includes('stafory.test');

// API url endpoint
const DEV_ENDPOINT = 'https://beta.robotvera.com/api/v2';
const PROD_ENDPOINT = "https://hr.robotvera.ru/api/v2";
const TEST_ENDPOINT = `${protocol}//${host.replace(
    'cabinet',
    'robotvera'
)}/api/v2`;

let apiEndpoint;

async function checkTestEndPoint() {
    const notificationStyle = {
        wordBreak: 'break-word',
        width: 400,
        marginLeft: -10,
        marginTop: 50
    };

    try {
        await fetch(TEST_ENDPOINT);

        notification.info({
            message: 'Endpoint info',
            description: 'Test endpoint is available',
            style: notificationStyle
        });

        apiEndpoint = TEST_ENDPOINT;
    } catch (err) {
        console.warn(err);

        notification.error({
            message: 'Endpoint error',
            description: 'Test api error instead Beta api',
            style: notificationStyle
        });

        apiEndpoint = DEV_ENDPOINT;
    }
}

/**
 * Available endpoints (check)
 * 1. beta.robotvera.com (+)
 * 2. beta-en.robotvera.com (?)
 * 3. robotvera.develop.stafory.test (+)
 * 4. robotvera.BRANCH_NAME.stafory.test (?)
 *
 * all endpoints ended with "stafory.test" need VPN connection
 */
function getEndpoint() {
    if ((isDev || isTest) && LS.get('endpoint')) {
        return LS.get('endpoint');
    }

    if (isDev) {
        return DEV_ENDPOINT;
    }

    if (isTest) {
        checkTestEndPoint();
        return DEV_ENDPOINT;
    }

    return PROD_ENDPOINT;
}
apiEndpoint = getEndpoint();

function createFormData(body) {
    const formData = new FormData();

    Object.keys(body).forEach(key => {
        if (body[key]) {
            formData.append(key, body[key]);
        }
    });

    return formData;
}

function redirectToLogin() {
    LS.remove(USER_TOKEN);
    SS.remove(LOGIN_AS_TOKEN);

    window.location.href = '/auth/login';
}

/**
 * Request method for working with API
 * @param {String} method - One of ["GET", "POST", "DELETE", "PATCH"]
 * @param {String} path - Api uri
 * @param {Object} body - data for api request
 * @param {Object} headersOpts - extra options for headers
 */
async function request(method, path, body, headersOpts = {}) {
    const requestWithAuth = headersOpts.authorization !== false;
    // old loginAs logic(with impersonate token) was removed in [RV-3136]
    const userToken = SS.get(LOGIN_AS_TOKEN) || LS.get(USER_TOKEN);

    if (requestWithAuth && !userToken) {
        redirectToLogin();
    }

    const { contentType } = headersOpts;
    const Authorization = requestWithAuth ? `Token ${userToken}` : null;
    const noMultipart = contentType !== 'multipart/form-data';
    const requestBody = noMultipart
        ? JSON.stringify(body)
        : createFormData(body);

    const options = {
        method,
        credentials: 'same-origin',
        headers: {
            Accept: 'application/json',
            ...(noMultipart && {
                'Content-Type': 'application/json'
            }),
            ...(Authorization && { Authorization })
        },
        ...(body && { body: requestBody })
    };

    const response = await fetch(`${apiEndpoint}${path}`, options);

    if (response.status === 401) {
        redirectToLogin();
    }

    const { ok, status } = response;

    try {
        const responseData = await response.json();

        // TODO: delete json after migration to without ".json()"
        return { ok, status, json: () => responseData, data: responseData };
    } catch (jsonError) {
        console.warn('toJSON error', jsonError);
    }

    console.warn('status:', status);
    return response;
}

const api = {
    get: (path, headersOpts) => request('GET', path, null, headersOpts),
    delete: (path, headersOpts) => request('DELETE', path, null, headersOpts),
    post: (path, data = {}, headersOpts) =>
        request('POST', path, data, headersOpts),
    patch: (path, data = {}, headersOpts) =>
        request('PATCH', path, data, headersOpts)
};

if (isDev || isTest) {
    const utils = {
        setEndpoint(path) {
            const url = new URL(path);
            const newEndpoint = `${url.origin}/api/v2`;

            LS.set('endpoint', newEndpoint);

            return `New backend endpoint:\n${newEndpoint}\nIt was saved in localStorage\n\nReload to continue`;
        },
        cleanEndpoint() {
            LS.remove('endpoint');

            return 'Endpoint was cleaned\n\nReload to continue with default endpoint';
        }
    };

    window.api = { ...api, ...utils };
}

export default api;
