import { number, shape, arrayOf, string, bool, func } from 'prop-types';

const statsType = shape({
    candidatesCount: shape({
        totalCandidates: number,
        processedCandidates: number,
        resultedCandidates: number,
        responseCandidates: number,
        phoneInterviewCandidates: number,
        interviewCandidates: number,
        interviewDoneCandidates: number,
        hiredCandidates: number
    }),
    vacancies: arrayOf(
        shape({
            key: string,
            value: string
        })
    ),
    visibleStages: arrayOf(string),
    isLoaded: bool,
    handleSettings: func
});

export default statsType;
