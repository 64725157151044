import { string, number, shape, oneOfType } from 'prop-types';

const vacancyDataTypes = shape({
    id: string,
    name: string,
    key: string,
    city: string,
    region: string,
    modified_at: string,
    status: string,
    candidates: oneOfType([string, number]),
    responses: oneOfType([string, number]),
    emotion: string,
    speed: number,
    speaker: string,
    ask_repeat: string
    // TODO: add new fields from Vacancy Model...
});

export default vacancyDataTypes;
