import { VacanciesStore } from './vacanciesStore';
import { VacancyStore } from './vacancyStore';
import { UserStore } from './userStore';
import { NotificationStore } from './notificationStore';
import { CandidatesStore } from './candidatesStore';
import { CandidateStore } from './candidateStore';
import { StatsStore } from './statsStore';
import { AppStore } from './appStore';
import { TeamsStore } from './teamsStore';

class RootGlobalStore {
    constructor() {
        this.appStore = new AppStore(this);
        this.userStore = new UserStore(this);
        this.vacanciesStore = new VacanciesStore(this);
        this.vacancyStore = new VacancyStore(this);
        this.notificationStore = new NotificationStore(this);
        this.candidatesStore = new CandidatesStore(this);
        this.candidateStore = new CandidateStore(this);
        this.statsStore = new StatsStore(this);
        this.teamsStore = new TeamsStore(this);
    }
}

export default new RootGlobalStore();
