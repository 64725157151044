import React from 'react';
import PropTypes from 'prop-types';
import cssModules from 'hoc/cssModules';

import styles from './Spinner.module.styl';

function Spinner({ className }) {
    return (
        <div styleName="spinner" className={className}>
            <span styleName="spinner__loader" />
        </div>
    );
}

Spinner.propTypes = {
    className: PropTypes.string
};

export default cssModules(styles)(Spinner);
