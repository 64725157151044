import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import withModules from 'hoc/cssModules';
import Routes from 'components/Router/Routes';
import Router from 'components/Router';
import Metrics from 'components/Metrics';
import DevTools from 'components/DevTools';

import { LocaleProvider as AntLocaleProvider } from 'antd';
import antdRU from 'antd/lib/locale-provider/ru_RU';
import antdEN from 'antd/lib/locale-provider/en_US';

import moment from 'moment';
import 'moment/locale/ru';

// Loader image
import loader from 'assets/images/loader.svg';

// Root styles
import 'theme/base.less';
import styles from './Root.module.styl';

// Libs locales
const antdLocales = {
    en: antdEN,
    ru: antdRU
};

// Fix for React router blocked updates
// https://github.com/ReactTraining/react-router/issues/5895
// eslint-disable-next-line react/destructuring-assignment
// const RouterRenderer = withRouter(props => props.render());

@inject(store => ({
    localizationStore: store.localizationStore
}))
@observer
@withModules(styles)
class Root extends Component {
    static propTypes = {
        localizationStore: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            appLang: PropTypes.string
        }).isRequired,
        isDevelopment: PropTypes.bool.isRequired,
        history: PropTypes.object // eslint-disable-line react/forbid-prop-types
    };

    render() {
        const {
            history,
            isDevelopment,
            localizationStore: { isLoaded, appLang }
        } = this.props;

        if (!isLoaded) {
            return (
                <div styleName="loader">
                    <img styleName="loader__image" src={loader} alt="loader" />
                </div>
            );
        }

        moment.locale(appLang);

        return (
            <AntLocaleProvider locale={antdLocales[appLang]}>
                <Fragment>
                    <DevTools isDevelopment={isDevelopment} />

                    <Router history={history}>
                        <Routes history={history} />
                    </Router>

                    <Metrics />
                </Fragment>
            </AntLocaleProvider>
        );
    }
}

Root.propTypes = {
    isDevelopment: PropTypes.bool.isRequired,
    history: PropTypes.object // eslint-disable-line react/forbid-prop-types
};

export default Root;
