import Raven from 'raven-js';

const SENTRY_KEY = 'e587f73b8fc6488b901bcd106f537bb1';
const SENTRY_APP = '1253460';

const SENTRY_URL = `https://${SENTRY_KEY}@sentry.io/${SENTRY_APP}`;

function initSentry(options = {}) {
    Raven.config(SENTRY_URL, options).install();
}

function logException(exception, content) {
    Raven.captureException(exception, { extra: content });

    console.error(exception);
}

function testError() {
    logException(new Error('Test error!'), { message: 'Test error!' });
}

export { initSentry, logException, testError };
