import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import gtmParts from 'react-google-tag-manager';

class GoogleTagManager extends Component {
    static propTypes = {
        gtmId: PropTypes.string.isRequired,
        dataLayerName: PropTypes.string,
        // eslint-disable-next-line
        additionalEvents: PropTypes.object,
        previewVariables: PropTypes.string,
        scriptId: PropTypes.string,
        scheme: PropTypes.string
    };

    static defaultProps = {
        dataLayerName: 'dataLayer',
        scriptId: 'react-google-tag-manager-gtm',
        additionalEvents: {},
        previewVariables: null,
        scheme: 'https:'
    };

    componentDidMount() {
        const { dataLayerName, scriptId } = this.props;

        if (!window[dataLayerName]) {
            const gtmScriptNode = document.getElementById(scriptId);
            // https://github.com/holidaycheck/react-google-tag-manager#notes
            // eslint-disable-next-line no-eval
            eval(gtmScriptNode.textContent);
        }
    }

    render() {
        const {
            gtmId: id,
            dataLayerName,
            scriptId,
            additionalEvents,
            scheme,
            previewVariables
        } = this.props;
        const gtm = gtmParts({
            id,
            dataLayerName,
            additionalEvents,
            previewVariables,
            scheme
        });

        return (
            <Fragment>
                <div>{gtm.noScriptAsReact()}</div>
                <div id={scriptId}>{gtm.scriptAsReact()}</div>
            </Fragment>
        );
    }
}

export default GoogleTagManager;
