/* eslint-disable func-names */
import moment from 'moment';
import { AsYouType } from 'libphonenumber-js';

import { YANDEX_ID } from 'constants/marketing';

class Helpers {
    getURLParam(param) {
        const urlObj = new URL(window.location.href);
        return urlObj.searchParams.get(param);
    }

    // deformat tel
    deformatPhone(phone) {
        return phone.replace(/[\s\D]/gi, '');
    }

    formatPhone(value) {
        // backend deletes such symbols as spaces, pluses...
        // but telInut component doesn't work correct with unformatted value
        const withPlus =
            value.includes('+') || value.length === 0 ? value : `+${value}`;

        const formattedValue = new AsYouType().input(withPlus);

        return formattedValue;
    }

    // Capitalize first letter in word
    capitalize(string) {
        return string.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
    }

    // Removing html tags from string
    possibleHtmlToText(string) {
        const div = document.createElement('div');

        div.innerHTML = string;

        return div.textContent || div.innerText || '';
    }

    convertFileToBase64(file, callback) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function() {
            callback(reader.result);
        };
        reader.onerror = function(error) {
            console.error('Error: ', error);
        };
    }

    createTwoDigitsDate(string) {
        const stringNum = `${string}`;

        return stringNum.length > 1 ? `${stringNum}` : `0${stringNum}`;
    }

    convertUTCDate(utc) {
        const date = new Date(utc);
        const year = date.getFullYear();
        const month = this.createTwoDigitsDate(date.getMonth() + 1);
        const day = this.createTwoDigitsDate(date.getDate());
        const hours = this.createTwoDigitsDate(date.getHours());
        const minutes = this.createTwoDigitsDate(date.getMinutes());

        return `${hours}:${minutes} ${day}.${month}.${year}`;
    }

    copyToBuffer(text) {
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
                ? document.getSelection().getRangeAt(0)
                : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    }

    /**
     *
     * @param {Moment} date - current date
     * @param {Number || 'unlimited'} past - disable days in past
     * @param {Number || 'unlimited'} future - disable days in future
     */
    disabledDate(date, { past, future }) {
        return (
            date >
                (future === 'unlimited'
                    ? moment()
                    : moment().add(future, 'days')) ||
            date <=
                (past === 'unlimited'
                    ? moment()
                    : moment().subtract(past, 'days'))
        );
    }

    /**
     * create custom delay
     * @param {Number} ms - wait milliseconds
     */
    wait(ms = 2000) {
        return new Promise(res => setTimeout(res, ms));
    }

    /**
     * remove html symbols
     * @param {String} stringWithHTML
     */
    removeHTML(stringWithHTML) {
        return stringWithHTML.replace(/(<[^>]*>)|(\s)/g, '');
    }

    /**
     * Save text as txt file
     * @param {String} text - what text to save
     * @param {String} filename
     */
    downloadTxt(text, filename = 'test.txt') {
        const pom = document.createElement('a');
        pom.setAttribute(
            'href',
            `data:text/plain;charset=utf-8, ${encodeURIComponent(text)}`
        );
        pom.setAttribute('download', filename);

        if (document.createEvent) {
            const event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }
    }

    /**
     * Function to check browser
     *
     * @param {String} browser
     */
    checkBrowser(browser) {
        const browserLowerCase = browser.toLowerCase();
        return navigator.userAgent.toLowerCase().indexOf(browserLowerCase) > -1;
    }

    childOfNode(prev, child) {
        let node = child;
        const someFunc = parent => parent === node;

        while (node != null) {
            const isTrue = Array.isArray(prev)
                ? prev.some(someFunc)
                : prev === node;

            if (isTrue) {
                return true;
            }

            node = node.parentNode;
        }

        return false;
    }

    escape(text) {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    /*
     * more accurate data type definition as opposed to typeof
     * for example:
     *
     * typeof(new Array())  === "object";
     * typeof(new Date())   === "object";
     * typeof(new RegExp()) === "object";
     *
     * Object.prototype.toString.call(new Array()).slice(8, -1).toLowerCase()  === "array";
     * Object.prototype.toString.call(new Date()).slice(8, -1).toLowerCase()   === "date";
     * Object.prototype.toString.call(new RegExp()).slice(8, -1).toLowerCase() === "regexp";
     */
    typeOf(data) {
        return Object.prototype.toString
            .call(data)
            .slice(8, -1)
            .toLowerCase();
    }

    sendGoal(goal) {
        const { ga } = window;
        const yaCounter = window[`yaCounter${YANDEX_ID}`];

        if (ga) {
            ga('send', 'event', {
                eventCategory: 'New cabinet',
                eventAction: 'click',
                eventLabel: goal
            });
        }

        if (yaCounter) {
            yaCounter.reachGoal(goal);
        }
    }

    getVacancyLandingHref(id) {
        const currentHref = window.location.origin;

        return /(localhost)|(beta.)/.test(currentHref)
            ? `https://beta.robotvera.com/stafory/${id}/vacancy`
            : `${currentHref.replace('.app', '')}/stafory/${id}/vacancy`;
    }
}

export default new Helpers();
