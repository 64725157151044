import { number, shape, func, string, bool, object } from 'prop-types';

const routingTypes = shape({
    title: string,
    breadcrumbs: func,
    history: shape({
        action: string,
        length: number,
        location: shape({
            pathname: string,
            search: string,
            hash: string,
            key: string
        })
    }),
    computedMatch: shape({
        isExact: bool,
        pathname: string,
        url: string,
        params: object
    }),
    location: shape({
        pathname: string,
        search: string,
        hash: string,
        key: string,
        isPrivate: bool
    }),
    setRoute: func,
    setTitle: func,
    go: func,
    goBack: func,
    goForward: func
});

export default routingTypes;
