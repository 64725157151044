import React from 'react';
import PropTypes from 'prop-types';

function DevTools({ isDevelopment }) {
    if (isDevelopment) {
        const MxTools = require('mobx-react-devtools').default;
        return <MxTools />;
    }

    return null;
}

DevTools.propTypes = {
    isDevelopment: PropTypes.bool.isRequired
};

export default DevTools;
