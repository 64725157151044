import { string, number, shape, arrayOf, oneOfType } from 'prop-types';

const candidateDataTypes = shape({
    name: string,
    id: string,
    key: string,
    vacancy_name: string,
    tel: string,
    source: string,
    created_at: string,
    modified_at: string,
    status: oneOfType([number, string]),
    record_url: string,
    dialog: arrayOf(
        shape({
            answer: string,
            question: string
        })
    )
});

export default candidateDataTypes;
