import { observable, action, computed } from 'mobx';
import api from 'tools/api';
import helpers from 'tools/helpers';
import { trans } from 'tools/i18n';

import Vacancy from './models/vacancyModel';

const { sendGoal } = helpers;

class VacanciesStore {
    constructor(rootStore) {
        this.pageSize = 25;
        this.rootStore = rootStore;
    }

    /*
        TODO: Изменить флаг isFetching на статусы
        (error, warning, fetching, done)
    */
    @observable isFetching = false;

    @observable searchPhrase = '';

    @observable archiveSearchPhrase = '';

    @observable vacancies = [];

    @observable archivedVacancies = [];

    @observable totalVacancies = 0;

    @observable totalArchivedVacancies = 0;

    @observable vacanciesPage = 1;

    @observable archivedVacanciesPage = 1;

    @observable ordering = '-modified_at';

    @observable filters = '';

    // TODO: check this prop in vacancies task, I guess it needs to delete
    @observable fetchedCities = [];

    @action.bound
    changeSearchPhrase(value) {
        const val = value.trim();

        if (val[0] === '#') {
            this.searchPhrase = val;
            this.fetchVacancy(val);
        } else {
            this.searchPhrase = encodeURIComponent(val);
            this.fetchVacancies();
        }
    }

    @action.bound
    changeSearchPhraseForArchive(value) {
        const val = value.trim();
        this.archiveSearchPhrase = val;

        if (val[0] === '#') {
            this.fetchArchivedVacancy(val);
        } else {
            this.fetchArchivedVacancies();
        }
    }

    @action.bound
    async fetchVacancies(
        page = 1,
        pageSize = this.pageSize,
        searchPhrase = this.searchPhrase
    ) {
        this.vacanciesPage = page;
        this.isFetching = true;

        try {
            const response = await api.get(
                `/vacancy/?limit=${pageSize}&offset=${pageSize *
                    (page - 1)}&search=${searchPhrase}&ordering=${
                    this.ordering
                }&${
                    this.filters
                }&metrics=candidates&metrics=candidates_positive_response`
            );

            const { ok, data } = response;

            if (ok) {
                const vacanciesData = data;

                this.totalVacancies = vacanciesData.count;
                this.vacancies = vacanciesData.results.map(
                    vacancyData => new Vacancy(vacancyData)
                );
            }

            this.isFetching = false;
        } catch (err) {
            console.error('error:', err);

            this.isFetching = false;
        }
    }

    @action
    async updateVacancies() {
        try {
            if (this.searchPhrase[0] === '#') {
                this.fetchVacancy(this.searchPhrase);
            } else {
                const response = await api.get(
                    `/vacancy/?limit=${this.pageSize}&offset=${this.pageSize *
                        (this.vacanciesPage - 1)}&search=${
                        this.searchPhrase
                    }&ordering=${this.ordering}&${
                        this.filters
                    }&metrics=candidates&metrics=candidates_positive_response`
                );

                if (response.ok) {
                    const vacanciesData = await response.json();

                    this.totalVacancies = vacanciesData.count;
                    this.vacancies = vacanciesData.results.map(
                        vacancyData => new Vacancy(vacancyData)
                    );
                } else {
                    console.warn('status:', response.status);
                }
            }
        } catch (err) {
            console.error('error:', err);
        }
    }

    @action.bound
    async fetchArchivedVacancies(
        page = 1,
        pageSize = this.pageSize,
        searchPhrase = this.archiveSearchPhrase
    ) {
        this.archivedVacanciesPage = page;
        this.isFetching = true;

        try {
            const response = await api.get(
                `/vacancy/archive/?limit=${pageSize}&offset=${pageSize *
                    (page - 1)}&search=${searchPhrase}&ordering=${
                    this.ordering
                }&${this.filters}`
            );

            if (response.ok) {
                const archivedVacancies = await response.json();

                this.totalArchivedVacancies = archivedVacancies.count;
                this.archivedVacancies = archivedVacancies.results.map(
                    vacancyData => new Vacancy(vacancyData)
                );
            } else {
                console.warn('status:', response.status);
            }

            this.isFetching = false;
        } catch (err) {
            console.error('error:', err);

            this.isFetching = false;
        }
    }

    @action
    async fetchVacancy(vacancyId) {
        this.isFetching = true;

        try {
            const resp = await api.get(
                `/vacancy/${vacancyId.slice(
                    1
                )}/?metrics=candidates&metrics=candidates_positive_response`
            );
            const { status, ok: respOk } = resp;

            if (respOk) {
                const vacancyData = await resp.json();

                this.totalVacancies = 1;
                this.vacancies = [new Vacancy(vacancyData)];
            } else {
                console.warn('status:', status);
            }

            this.isFetching = false;
        } catch (err) {
            console.error(err);
            this.isFetching = false;
        }
    }

    @action
    async fetchArchivedVacancy(vacancyId) {
        this.isFetching = true;

        try {
            const resp = await api.get(
                `/vacancy/archive/${vacancyId.slice(1)}/`
            );
            const { status, ok: respOk } = resp;

            if (respOk) {
                const vacancy = await resp.json();

                this.totalArchivedVacancies = 1;
                this.archivedVacancies = [new Vacancy({ ...vacancy })];
            } else {
                console.warn('status:', status);
            }

            this.isFetching = false;
        } catch (err) {
            console.error(err);
            this.isFetching = false;
        }
    }

    @action.bound
    async deleteVacancy(vacancyId) {
        sendGoal('SEND_TO_ARCHIVE');

        try {
            const response = await api.delete(`/vacancy/${vacancyId}/`);

            if (response.ok) {
                this.notifications.setMessage(
                    trans('Успешно перенесено в архив'),
                    'success'
                );

                this.updateVacancies();
                this.fetchArchivedVacancies();
            } else {
                this.notifications.setMessage(
                    trans('Произошла ошибка при переносе в архив'),
                    'warning'
                );
            }
        } catch (err) {
            this.notifications.setMessage(
                trans('Произошла ошибка при переносе в архив'),
                'error'
            );
        }
    }

    @action.bound
    async unarchiveVacancy(vacancyId) {
        const res = await api.post(`/vacancy/archive/${vacancyId}/unarchive/`);

        if (res.ok) {
            this.notifications.setMessage(
                trans('Вакансия успешно восстановлена'),
                'success',
                'true'
            );

            this.updateVacancies();
            this.fetchArchivedVacancies();
        } else {
            this.notifications.setMessage(
                trans('Ошибка восстановления вакансии'),
                'error',
                'true'
            );
        }
    }

    @action.bound
    changeFilters(type, value) {
        if (value === 'Все') {
            this.filters = '';
        } else {
            this.filters = `${type}=${value}`;
        }

        this.fetchVacancies();
    }

    @action.bound
    changeOrdering(type, listIn = 'vacancies', strictType) {
        const isItStrictType = ['+', '-'].includes(type[0]);

        if (strictType || isItStrictType) {
            this.ordering = type;
        } else {
            this.ordering =
                this.ordering === `+${type}` ? `-${type}` : `+${type}`;
        }

        if (listIn === 'vacancies') {
            this.fetchVacancies();
        } else {
            this.fetchArchivedVacancies();
        }
    }

    /*
        TODO: Добавить флаг isFetching
    */
    @action.bound
    async fetchCities(value) {
        try {
            const response = await api.get(
                `/autocomplete/region/?query=${value}`
            );

            if (response.ok) {
                const data = await response.json();

                this.fetchedCities = data.results.map(city => ({
                    key: city,
                    value: city
                }));
            } else {
                console.warn('status:', response.status);
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    changeField(key, value) {
        this[key] = value;
    }

    /*
        TODO: добавить флаг isFetching
    */
    @action.bound
    async cancelModeration(vacancyId) {
        sendGoal('CANCEL_MODERATE');

        try {
            const response = await api.post(
                `/vacancy/${vacancyId}/cancel-moderation/`
            );
            const { status, ok: respOk } = response;

            if (respOk) {
                this.notifications.setMessage(
                    trans('Модерация вакансии отменена'),
                    'success'
                );

                this.updateVacancies();
            } else {
                this.notifications.setMessage(
                    trans('Операция не выполнена'),
                    'warning'
                );

                console.warn('status:', status, response);
            }
        } catch (err) {
            this.notifications.setMessage(
                trans('Операция не выполнена'),
                'error'
            );

            console.error(err);
        }
    }

    @action.bound
    async startVacancy(vacancyId) {
        sendGoal('START_VACANCY');

        try {
            const { ok, data } = await api.post(
                `/vacancy/${vacancyId}/campaign/`
            );

            if (ok) {
                this.notifications.setMessage(
                    trans('Операция выполнена успешно'),
                    'success'
                );

                this.updateVacancies();
            } else if (data.error === 'Empty user balance') {
                this.notifications.setMessage(
                    trans('Недостаточно средств для запуска кампании'),
                    'warning'
                );
            } else if (
                data.message ===
                'Hiring campaign may be launched from 8-00 am till 9-00 pm local time'
            ) {
                this.notifications.setMessage(
                    trans('Запуск доступен с 8:00 до 21:00'),
                    'warning'
                );
            } else {
                this.notifications.setMessage(
                    trans('Операция не выполнена'),
                    'warning'
                );
            }
        } catch (err) {
            console.error(err);
            this.notifications.setMessage(
                trans('Операция не выполнена'),
                'error'
            );
        }
    }

    @action.bound
    async stopVacancy(id) {
        const { ok } = await api.post(`/vacancy/${id}/stop-campaign/`);

        if (ok) {
            this.notifications.setMessage(
                trans('Операция выполнена успешно'),
                'success'
            );

            this.updateVacancies();
        } else {
            this.notifications.setMessage(
                trans('Операция не выполнена'),
                'warning'
            );
        }
    }

    /**
     * Aliases for short variables
     */

    @computed
    get notifications() {
        return this.rootStore.notificationStore;
    }
}

export default new VacanciesStore();
export { VacanciesStore };
