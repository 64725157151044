/* eslint-disable import/order */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import { authTypes } from 'types';

import SmartRoute from '../SmartRoute';
import NoMatch from '../NoMatch';
import routes from 'routes';

function Routes({ history, authStore }) {
    return (
        <Switch>
            {routes.map(route => {
                const routeProps = {
                    key: route.path,
                    ...route
                };

                if (route.private && !authStore.isAuthorized) {
                    return (
                        <Route
                            key="protection"
                            render={() => <Redirect to="/auth/login" />}
                        />
                    );
                }

                return <SmartRoute history={history} {...routeProps} />;
            })}

            <Route component={NoMatch} />
        </Switch>
    );
}

Routes.propTypes = {
    authStore: authTypes,
    // eslint-disable-next-line
    history: PropTypes.object.isRequired
};

export default inject('authStore')(Routes);
